import { ListItemText, Menu, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '12px',
    margin: '0',
    padding: '0',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuSmall = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '12px',
    margin: '0',
    padding: '0',
    width: '120px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuSmaller = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '12px',
    margin: '0',
    padding: '0',
    width: '80px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: '#3c3c3c !important',
    width: '275px',
    '&:hover': {
      color: '#3c3c3c !important',
      backgroundColor: 'rgba(255, 228, 38, 0.4);',
    },
    '&:focus': {
      color: '#3c3c3c !important',
      backgroundColor: 'rgba(255, 228, 38, 0.4);',
    },
  },
}))(MenuItem);

export const CustomDropdownReadOnly = (props) => {
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, key) => (
        <StyledMenuItem key={key}>
          <ListItemText
            style={{
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};

export const CustomDropdownWithSelect = (props) => {
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, index) => (
        <StyledMenuItem
          key={index}
          selected={props.value === option}
          onClick={(option) => props.setValue(index)}
        >
          <ListItemText
            style={{
              textAlign: 'left',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
          {props.value === option ? (
            <img
              style={{ float: 'right' }}
              src="Images/icon-check.png"
              alt="check"
            />
          ) : null}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};

export const CustomDropdownWithSelectSmall = (props) => {
  return (
    <StyledMenuSmall
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, index) => (
        <StyledMenuItem
          key={index}
          selected={props.value === option}
          onClick={(option) => props.setValue(index)}
        >
          <ListItemText
            style={{
              textAlign: 'left',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
          {props.value === option ? (
            <img
              style={{ float: 'right' }}
              src="Images/icon-check.png"
              alt="check"
            />
          ) : null}
        </StyledMenuItem>
      ))}
    </StyledMenuSmall>
  );
};

export const CustomDropdownWithSelectSmallImage = (props) => {
  const getIconUrl = (option) => {
    switch (option) {
      case 'Paris':
        return 'Images/img_flag/FR_flag.png'; // Path to Paris icon
      case 'Thailand':
        return 'Images/img_flag/TH_flag.png'; // Path to Thailand icon
      default:
        return ''; // Default case, no icon
    }
  };
  return (
    <StyledMenuSmaller
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, index) => (
        <StyledMenuItem
          key={index}
          selected={props.value === option}
          onClick={(option) => props.setValue(index)}
        >
          <img
            src={getIconUrl(props.getOptionLabel(option))} // Set the src to the icon URL
            alt={option} // Alt text as the option name
            style={{ marginRight: '10px', width: '20px', height: '20px' }} // Styling for the icon
          />

          {props.value === option ? (
            <img
              style={{ float: 'right' }}
              src="Images/icon-check.png"
              alt="check"
            />
          ) : null}
        </StyledMenuItem>
      ))}
    </StyledMenuSmaller>
  );
};


export const CustomDropdownWithSelectSmallStatus = (props) => {
  const getColor = (option) => {
    switch (option) {
      case 'TO_BE_VALIDATED_SUPER_CONSULTANT':
        return 'rgb(0, 0, 0)';
      case 'TO_BE_VALIDATED_CLIENT':
        return '#ffb200';
      case 'TO_BE_FILTERED_CONSULTANT':
        return '#1073d7';
      case 'TO_BE_COMMANDED':
        return '#2eab08';
      case 'REFUSED_CLIENT':
        return '#f00';
      case 'REFUSED_PIXALIONE':
        return '#a30cb7';
      default:
        return '#696969';
    }
  };

  return (
    <StyledMenuSmaller
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, index) => (
        <StyledMenuItem
          key={index}
          selected={props.value === option}
          onClick={() => props.setValue(option)}
        >
          <span
            style={{
              display: 'inline-block',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: getColor(props.getOptionLabel(option)),
              marginRight: '10px',
            }}
          ></span>
          {props.value === option ? (
            <img
              style={{ float: 'right' }}
              src="Images/icon-check.png"
              alt="check"
            />
          ) : null}
        </StyledMenuItem>
      ))}
    </StyledMenuSmaller>
  );
};


export const CustomDropdownWithSelectSmallDisabled = (props) => {
  return (
    <StyledMenuSmall
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, index) => (
        <StyledMenuItem
          key={index}
          selected={props.value === option}
          onClick={() => { }}
        >
          <ListItemText
            style={{
              textAlign: 'left',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
          {props.value === option ? (
            <img
              style={{ float: 'right' }}
              src="Images/icon-check.png"
              alt="check"
            />
          ) : null}
        </StyledMenuItem>
      ))}
    </StyledMenuSmall>
  );
};
