import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JsonToExcel } from 'react-json-to-excel';
import { getEmailPart, getNamePart } from 'utils/StringUtils';
import {
  getPublishStatusText,
  getRedactStatusText,
} from 'utils/netlinking/NetlinkingStatus';

export const ExcelExportButtonSourcing = (props) => {
  const { t } = useTranslation();
  const [netlinkingExcelData, setNetlinkingExcelData] = useState(null);
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  function handleLanguageId(value) {
    let result;

    switch (value) {
      case 6:
        result = 'Fr';
        break;
      case 1:
        result = 'En';
        break;
      case 2:
        result = 'De';
        break;
      case 3:
        result = 'Es';
        break;
      case 4:
        result = 'It';
        break;
      case 5:
        result = 'Pt';
        break;
      default:
        result = 'Fr';
        break;
    }

    return result;
  }

  const handleClick = async () => {
    const dataToExport =
      props.netlinkingExportList.length > 0
        ? props.netlinkingExportList
        : props.netlinkingFilteredListData.length > 0
          ? props.netlinkingFilteredListData
          : props.netlinkingData;
    const tmpExcelData = dataToExport.map((netlinkingData) => ({
      [t('Domaine')]: netlinkingData.netlinking.domain,
      [t('Domaine Simple')]: netlinkingData.netlinking.domain
        .replace('https://', '')
        .replace('http://', '')
        .replace('www.', ''),
      [t('Langue')]: handleLanguageId(netlinkingData.netlinking.languageId),
      [t('Thématique')]: netlinkingData.netlinking.themesList,
      [t('Topic Majestic')]: netlinkingData.netlinking.topicsList,
      [t('Kw positionés')]: netlinkingData.netlinking.kwPositioned,
      [t('Trafic')]: netlinkingData.netlinking.trafic,
      [t('TF')]: netlinkingData.netlinking.tF,
      [t('CF')]: netlinkingData.netlinking.cF,
      [`${t('Tarif')} ` + ' HR']: netlinkingData.netlinking.tarifHr,
      [`${t('Tarif')} ` + ' RC']: netlinkingData.netlinking.tarifRc,
      [t('Tarif plateforme') + ' HR']: netlinkingData?.netlinking?.platformHR,
      [t('Tarif plateforme') + ' RC']: netlinkingData?.netlinking?.platformRC,
      [t('Nom platforme')]: netlinkingData.netlinking.platformName,
      [t('Condition')]: netlinkingData.netlinking.conditions,
      [t('Contacté le')]: netlinkingData.netlinking.contactedTime,
      [t('Contact')]: getEmailPart(netlinkingData.netlinking.contact),
      [t('Nom contact')]: getNamePart(netlinkingData.netlinking.contact),
      [t('Téléphone')]: netlinkingData.netlinking.contactPhoneNumber,

      [t('Responsable rédaction')]: netlinkingData.responsibleRedaction,
      [t('Responsable publication	')]: netlinkingData.responsiblePublication,
      [t('Date de programmation')]: netlinkingData.programmationDate,
      [t('Date de publication estimé')]: netlinkingData.publicationDate,
      [t('Contacté le')]: netlinkingData.contactDate,
      [t('lien article')]: netlinkingData.articleLink,
      [t('lien du spot')]: netlinkingData.spotLink,
      [t('ancre de lien')]: netlinkingData.ancreLink,
      [t('lien à insérer')]: netlinkingData.insertionLink,
      [t('Etat publication')]: getPublishStatusText(
        netlinkingData.publicationStatus,
        t
      ),
      [t('Etat rédaction')]: getRedactStatusText(
        netlinkingData.redactionStatus,
        t
      ),
    }));
    setNetlinkingExcelData(tmpExcelData);
    await sleep(500);
    document.querySelector('.download-button').click();
  };

  return (
    <div className="container-excel img-container">
      <div className={props.netlinkingExportList.length > 0 ? 'icon-btn-test' : ''}>
        <div >
          <img
            src="/Images/icon-excel.png"
            onClick={handleClick}
            className="App-logo-excel"
            alt="logo"
          />
        </div>
      </div>
      <div className="json-to-excel-container">
        <JsonToExcel
          data={netlinkingExcelData}
          fileName="netlinking-report"
          fields={[
            { label: 'domain', value: 'domain' },
            { label: 'cleanDomain', value: 'cleanDomain' },
            { label: 'language', value: 'language' },
            { label: 'themesList', value: 'themesList' },
            { label: 'topicsList', value: 'topicsList' },
            { label: 'kwPositioned', value: 'kwPositioned' },
            { label: 'trafic', value: 'trafic' },
            { label: 'tF', value: 'tF' },
            { label: 'cF', value: 'cF' },
            { label: 'tarifHr', value: 'tarifHr' },
            { label: 'tarifRc', value: 'tarifRc' },
            { label: 'conditions', value: 'conditions' },
            { label: 'contactedTime', value: 'contactedTime' },
            { label: 'contact', value: 'contact' },
          ]}
          buttonText="Export to Excel"
          btnColor="white"
          btnClassName="download-button"
        />
      </div>
    </div>
  );
};
