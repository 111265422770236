import ClientProfile from 'pages/Client/ClientProfile';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import { ClientHomePage } from 'pages/Client/ClientHomepage';
import { ClientAllCampaigns } from 'pages/Client/ClientAllCampaings';
import ClientInProgressOrders from 'pages/Client/ClientInProgressOrders';
import ClientOverDueOrders from 'pages/Client/ClientOverDueOrders';
import ClientFicheClient from 'pages/Client/ClientFicheClient';
import ClientOrdersByWebsite from 'pages/Client/ClientOrdersByWebsite';
import ClientCampaignDetail from 'pages/Client/ClientCampaignDetail';
import StillWorkingOnIt from './UnderConstruction';
import ClientOrdersOnline from 'pages/Client/ClientOnlineOdrers';
export default class ClientMainMenu extends React.Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route exact path="/Client" component={ClientHomePage} />
          <Route path="/Client/MainMenu" component={ClientHomePage} />
          <Route path="/Client/MonProfil" component={ClientProfile} />
          <Route path="/Client/AllCampaigns" component={ClientAllCampaigns} />
          <Route
            path="/Client/CampagnesEnCour"
            component={ClientInProgressOrders}
          />
          <Route
            path="/Client/CampagnesEnRetard"
            component={ClientOverDueOrders}
          />
          <Route
            path="/Client/FicheClient/:id"
            component={ClientFicheClient}
          ></Route>
          <Route
            path="/Client/Website/:id/Campagnes"
            component={ClientOrdersByWebsite}
          ></Route>
          <Route
            path="/Client/DetailsCampagne/:id"
            component={ClientCampaignDetail}
          />
          <Route
            path="/Client/CampagnesEnLigne"
            component={ClientOrdersOnline}
          ></Route>
          <Route
            path="/Client/LiensSupprimé"
            component={StillWorkingOnIt}
          ></Route>

        </Switch>
      </Layout>
    );
  }
}
