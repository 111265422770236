import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axiosPrivate from 'config/axiosPrivate';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { lower } from 'utils/StringUtils';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { adminOrderListSelector } from 'store/Admin/OrderList';
import { adminClientListSelector } from 'store/Admin/ClientList';

import { fetchClientList } from 'store/Admin/ClientList/features';
import { fetchAllOrders } from 'store/Admin/OrderList/features';
import { removeClient } from 'store/Admin/ClientList';

import { Loader } from 'components/shared/Loader';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';

import './Homepage.css';
import axiosPixLink from 'config/axiosPixLink';
import ConsultantOrders from './ConsultantOrders';
import { UserSessionSelector } from 'store/UserSession';
const API = {
  deleteWebsite: '/clientWebsite/',
};

export const ConsultantHomePage = () => {
  let { user } = useSelector(UserSessionSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  let { isLoading } = useSelector(adminOrderListSelector);

  const { t } = useTranslation();
  let { clients, isLoadingClients } = useSelector(adminClientListSelector);

  const [selectedWebsite, setSelectedWebsite] = useState(false);
  const [Consultantsclients, setConsultantsclients] = useState([]);
  const [isLoadinConsultantsclients, setIsLoadinConsultantsclients] =
    useState(false);

  const [urlSearch, setUrlSearch] = useHistoryState('urlSearch', '');
  const [unassignedCampaignsCount, setUnassignedCampaignsCount] = useState(0);
  const [assignedCampaignsCount, setAssignedCampaignsCount] = useState(0);
  const [overdueCampaignsCount, setOverdueCampaignsCount] = useState(0);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [onlineCampaignsCount, setOnlineCampaignsCount] = useState(0);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  useEffect(() => {
    document.title = t('PixLink - Mon tableau de bord');
    window.dispatchEvent(new Event('locationchange'));
  }, []);
  useEffect(() => {
    setIsLoadinConsultantsclients(true);
    axiosPixLink
      .post(`/website/findConsultantsWebsite`, user.email)
      .then((res) => {
        setConsultantsclients(res.data);
        setIsLoadinConsultantsclients(false);
      })
      .catch((err) => console.log(err));
    setIsLoadinConsultantsclients(false);
  }, []);
  useEffect(() => {
    axiosPixLink
      .post('/campaign/countConsultantsCampaigns/InProgress', user.email)
      .then((res) => setAssignedCampaignsCount(res.data.body))
      .catch((err) => console.log(err));
    axiosPixLink
      .post('/campaign/countConsultantsCampaigns/Online', user.email)
      .then((res) => setOnlineCampaignsCount(res.data.body))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axiosPixLink
      .post('/campaign/countConsultantsCampaigns/Overdue', user.email)
      .then((res) => setOverdueCampaignsCount(res.data.body))
      .catch((err) => console.log(err));
  }, []);

  const DeleteWebsite = () => {
    axiosPixLink
      .delete(`/website/${selectedWebsite.id}/delete`)
      .then(() => {
        setConfirmation(false);
        dispatch(removeClient(selectedWebsite.id));

        setErrorSuccessMsg(
          `${t('Le contrat de')} ${selectedWebsite.urlSite} ${t(
            'a été supprimé avec succès'
          )} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setConfirmation(false);
        switch (e.response.status) {
          case 500:
            setErrorSuccessMsg(
              t("il y'a des campagnes liés au site web ") +
              selectedWebsite.urlSite
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  return (
    <div>
      <div className="content-center">
        <div className="mb-2">
          <h1>Dashboard</h1>
        </div>
        <div className="row-homePage">
          <div className="col-12 col-md-4 mr-4">
            <div className="box-wrapper">
              <div className="box-list-header">
                <div className="box-title"> {t('Liste des clients')} </div>
                <div className="box-input-text">
                  <div className="input-group input-cs w-100">
                    <div className="input-group-text">
                      <i className="bi bi-search" />
                    </div>
                    <input
                      type="text"
                      value={urlSearch}
                      onChange={(event) => setUrlSearch(event.target.value)}
                      className="form-control ps-0"
                      placeholder={t('Rechercher')}
                    />
                  </div>
                </div>
              </div>
              {isLoadinConsultantsclients ? (
                <Loader />
              ) : (
                <div className="table-responsive">
                  <div className="box-table">
                    <table className="table-lists">
                      <thead>
                        <tr>
                          <th>{t('Nom client')}</th>
                          <th>{t('Campagnes')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Consultantsclients?.filter((x) => {
                          if (urlSearch && urlSearch.length > 0) {
                            return lower(x.urlSite).includes(lower(urlSearch));
                          }
                          return true;
                        }).map((item) => (
                          <tr key={item.id} style={{ cursor: 'pointer' }}>
                            <td
                              onClick={() => {
                                /*  dispatch(fetchWebsiteDisplay(item.urlSite));*/
                                history.push(
                                  '/Consultant/FicheClient/' + item.id
                                );
                              }}
                            >
                              <span>{item.urlSite}</span>
                            </td>

                            <td
                              onClick={() => {
                                /*  dispatch(setUrlSiteForDisplay(item.urlSite));
                                  dispatch(fetchWebsiteOrdersList(item.id));*/
                                /*history.push('/Consultant/CommandesduSiteweb');*/
                              }}
                            >
                              <span className="bt-circle-wh">
                                {item.numberOfCampaigns}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-8 mr-8">
            <div style={{ marginLeft: '3rem' }}>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div
                      className=" card box-wrapper box-pd box-pd-blue"
                      onClick={() =>
                        history.push('/Consultant/CampagnesEnCour')
                      }
                    >
                      <div className="box-header-icon">
                        <div className="bt-icon-list bt-icon-en-cour" />
                      </div>
                      <div className=" text-container label-text text-center text-wrap">
                        {t('Campagnes en cours')}
                      </div>
                      <div className="box-circle bg-blue">
                        {assignedCampaignsCount ? assignedCampaignsCount : '0'}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div
                      className="card box-wrapper box-pd box-pd-darkorange"
                      onClick={() =>
                        history.push('/Consultant/CampagnesEnRetard')
                      }
                    >
                      <div className="box-header-icon">
                        <div className="bt-icon-list bt-icon-en-retard" />
                      </div>
                      <div className="text-container label-text text-center text-wrap">
                        {t('Campagnes en retard')}
                      </div>
                      <div className="box-circle bg-darkorange">
                        {overdueCampaignsCount ? overdueCampaignsCount : '0'}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div
                      className="card box-wrapper box-pd box-pd-green"
                      onClick={() =>
                        history.push('/Consultant/CampagnesEnLigne')
                      }
                    >
                      <div className="box-header-icon">
                        <div className="bt-icon-list bt-icon-valid" />
                      </div>
                      <div className="text-container label-text text-center text-wrap">
                        {t('Campagnes en ligne')}
                      </div>
                      <div className="box-circle bg-green">
                        {onlineCampaignsCount ? onlineCampaignsCount : '0'}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div
                      className="card box-wrapper box-pd box-pd-gris"
                      onClick={() => history.push('/Consultant/LiensSupprimé')}
                    >
                      <div className="box-header-icon">
                        <div className="bt-icon-list bt-icon-attr" />
                      </div>
                      <div className=" text-container label-text text-center text-wrap">
                        {t('Liens supprimés')}
                      </div>
                      <div className="box-circle bg-gris">
                        {!isLoading ? 0 : '0'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={DeleteWebsite}
      />
    </div>
  );
};
