const StillWorkingOnIt = () => {
  return (
    <div className="centered-under-construction">
      <div class="box-ribbon">
        <div class="ribbon ribbon-top-left"><span>under construction</span></div>
        <div class="ribbon ribbon-top-right"><span>under construction</span></div>
        <img src="/Images/construction.jpg" alt="Under construction" />
        <div class="ribbon ribbon-bottom-left"><span>under construction</span></div>
        <div class="ribbon ribbon-bottom-right"><span>under construction</span></div>
      </div>
      <div className="content-under-construction">
      </div>
    </div>
  );
};

export default StillWorkingOnIt;
