import Button from '@material-ui/core/Button';
import CheckBox from 'components/shared/CheckBox';
import { CustomTooltipSmall } from 'components/shared/CustomTooltip';
import axiosPixLink from 'config/axiosPixLink';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  CustomDropdownWithSelect,
  CustomDropdownWithSelectSmallDisabled,
} from 'components/shared/DropDown/CustomDropdown';
import { CustomDropdownSingleRedactionPublicationSpotDisabled } from 'components/shared/DropDown/CustomDropdownStatusSpots';
import LinkIconWithInputDisabled from 'components/shared/LinkIconWithInputDisabled';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserSessionSelector } from 'store/UserSession';
import { browserList, detectBrowser } from 'utils/BrowserDetection';
import {
  PIXLINK_PUBLISH_STATUS,
  PIXLINK_REDAC_STATUS,
  PIXLINK_STATUS,
} from 'utils/Constants';
import DisabledSmallCalendarIcon from 'components/shared/InputField/DisabledSmallCalendarIcon';
import { isIdInArray } from 'utils/tables/tables';
import LinkIconWithInput from 'components/shared/LinkIconWithInput';
import { checkDisabledCell } from 'utils/netlinking/DisabledCells';
import { getIconUrl } from 'utils/netlinking/NetlinkingStatus';
import LinkIconWithInputShownDisabled from 'components/shared/LinkIconWithInputShownDisabled';
export function SuperConsultantSelectedSpotTable({
  shownNetlinkingFilteredList,
  nameSearch,
  onNetlinkingContactFilterChange,
  contactFilter,
  sendNetlinkingExport,
  ordersPerPage,
  removeNetlinkingId,
  columns,
  sendUpdateSaveSelectedNetlinking,
  selectedStatus,
  wantedNetlinkings,
  sendLengthArrayShown,
  campaignStatus,
  historicBackLinkings,
  campaignType
}) {
  const { t } = useTranslation();
  let { user } = useSelector(UserSessionSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = ordersPerPage || 20;
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortedItems, setSortedItems] = useState([]);
  const [statusSortedItems, setstatusSortedItems] = useState(null);
  const [tableExport, setTableExport] = useState([]);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [confirmationDeleteForce, setConfirmationDeleteForce] = useState(false);
  const [itemTobeDeleted, setItemToBeDeleted] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemIdToBeChanged, setItemIdToBeChanged] = useState(null);
  const [isDisabledCell, setIsDisabledCell] = useState(
    checkDisabledCell(campaignStatus)
  );
  useEffect(() => {
    if (selectedStatus?.includes('')) {
      sendLengthArrayShown(
        shownNetlinkingFilteredList.filter((item) =>
          item?.netlinking?.domain
            ?.toLowerCase()
            .includes(nameSearch?.toLowerCase())
        ).length
      );
    } else {
      sendLengthArrayShown(
        statusSortedItems !== null
          ? statusSortedItems.filter((item) =>
            item?.netlinking?.domain
              ?.toLowerCase()
              .includes(nameSearch?.toLowerCase())
          ).length
          : sortedItems.filter((item) =>
            item?.netlinking?.domain
              ?.toLowerCase()
              .includes(nameSearch?.toLowerCase())
          ).length
      );
    }
  }, [statusSortedItems, shownNetlinkingFilteredList, nameSearch]);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [confirmationMsgDeleteForce, setConfirmationMsgDeleteForce] =
    useState('');
  const [itemResponsiblePublicationId, setItemResponsiblePublicationId] =
    useState(-1);
  const [isSuccess, setIsSuccess] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    const sorted = [...shownNetlinkingFilteredList].sort((fullA, fullB) => {
      const a = fullA.netlinking;
      const b = fullB.netlinking;
      if (sortColumn === 'domain') {
        return sortDirection === 'asc'
          ? a.domain.localeCompare(b.domain)
          : b.domain.localeCompare(a.domain);
      } else if (sortColumn === 'languageId') {
        return sortDirection === 'asc'
          ? a.languageId - b.languageId
          : b.languageId - a.languageId;
      } else if (sortColumn === 'themesList') {
        return sortDirection === 'asc'
          ? (a.themesList || '').localeCompare(b.themesList || '')
          : (b.themesList || '').localeCompare(a.themesList || '');
      } else if (sortColumn === 'topicsList') {
        return sortDirection === 'asc'
          ? (a.topicsList || '').localeCompare(b.topicsList || '')
          : (b.topicsList || '').localeCompare(a.topicsList || '');
      } else if (sortColumn === 'contact') {
        return sortDirection === 'asc'
          ? (getEmailPart(a.contact) || '').localeCompare(
            getEmailPart(b.contact) || ''
          )
          : (getEmailPart(b.contact) || '').localeCompare(
            getEmailPart(a.contact) || ''
          );
      } else if (sortColumn === 'contactName') {
        return sortDirection === 'asc'
          ? (getNamePart(a.contact) || '').localeCompare(
            getNamePart(b.contact) || ''
          )
          : (getNamePart(b.contact) || '').localeCompare(
            getNamePart(a.contact) || ''
          );
      } else if (sortColumn === 'contactedAt') {
        return sortDirection === 'asc'
          ? (a.contactedTime || '').localeCompare(b.contactedTime || '')
          : (b.contactedTime || '').localeCompare(a.contactedTime || '');
      } else if (sortColumn === 'kwPosition') {
        return sortDirection === 'asc'
          ? (a.kwPositioned || 0) - (b.kwPositioned || 0)
          : (b.kwPositioned || 0) - (a.kwPositioned || 0);
      } else if (sortColumn === 'Trafic') {
        return sortDirection === 'asc'
          ? (a.trafic || 0) - (b.trafic || 0)
          : (b.trafic || 0) - (a.trafic || 0);
      } else if (sortColumn === 'tF') {
        return sortDirection === 'asc'
          ? (a.tF || 0) - (b.tF || 0)
          : (b.tF || 0) - (a.tF || 0);
      } else if (sortColumn === 'cF') {
        return sortDirection === 'asc'
          ? (a.cF || 0) - (b.cF || 0)
          : (b.cF || 0) - (a.cF || 0);
      } else if (sortColumn === 'tarifHr') {
        return sortDirection === 'asc'
          ? (a.tarifHr || 0) - (b.tarifHr || 0)
          : (b.tarifHr || 0) - (a.tarifHr || 0);
      } else if (sortColumn === 'tarifRc') {
        return sortDirection === 'asc'
          ? (a.tarifRc || 0) - (b.tarifRc || 0)
          : (b.tarifRc || 0) - (a.tarifRc || 0);
      } else if (sortColumn === 'platformRC') {
        return sortDirection === 'asc'
          ? (a.platformRC || 0) - (b.platformRC || 0)
          : (b.platformRC || 0) - (a.platformRC || 0);
      } else if (sortColumn === 'platformHR') {
        return sortDirection === 'asc'
          ? (a.platformHR || 0) - (b.platformHR || 0)
          : (b.platformHR || 0) - (a.platformHR || 0);
      } else if (sortColumn === 'redacResponsible') {
        return sortDirection === 'asc'
          ? (fullA.responsibleRedaction || '').localeCompare(
            fullB.responsibleRedaction || ''
          )
          : (fullB.responsibleRedaction || '').localeCompare(
            fullA.responsibleRedaction || ''
          );
      } else if (sortColumn === 'publicationResponsible') {
        return sortDirection === 'asc'
          ? (fullA.responsiblePublication || '').localeCompare(
            fullB.responsiblePublication || ''
          )
          : (fullB.responsiblePublication || '').localeCompare(
            fullA.responsiblePublication || ''
          );
      } else if (sortColumn === 'redactionState') {
        return sortDirection === 'asc'
          ? (fullA.redactionStatus || '').localeCompare(
            fullB.redactionStatus || ''
          )
          : (fullB.redactionStatus || '').localeCompare(
            fullA.redactionStatus || ''
          );
      } else if (sortColumn === 'publicationState') {
        return sortDirection === 'asc'
          ? (fullA.publicationStatus || '').localeCompare(
            fullB.publicationStatus || ''
          )
          : (fullB.publicationStatus || '').localeCompare(
            fullA.publicationStatus || ''
          );
      } else if (sortColumn === 'programationDate') {
        return sortDirection === 'asc'
          ? (fullA.programmationDate || '').localeCompare(
            fullB.programmationDate || ''
          )
          : (fullB.programmationDate || '').localeCompare(
            fullA.programmationDate || ''
          );
      } else if (sortColumn === 'publicationDate') {
        return sortDirection === 'asc'
          ? (fullA.publicationDate || '').localeCompare(
            fullB.publicationDate || ''
          )
          : (fullB.publicationDate || '').localeCompare(
            fullA.publicationDate || ''
          );
      } else if (sortColumn === 'contactDate') {
        return sortDirection === 'asc'
          ? (fullA.contactDate || '').localeCompare(fullB.contactDate || '')
          : (fullB.contactDate || '').localeCompare(fullA.contactDate || '');
      } else if (sortColumn === 'articleLink') {
        return sortDirection === 'asc'
          ? (fullA.articleLink || '').localeCompare(fullB.articleLink || '')
          : (fullB.articleLink || '').localeCompare(fullA.articleLink || '');
      } else if (sortColumn === 'spotLink') {
        return sortDirection === 'asc'
          ? (fullA.spotLink || '').localeCompare(fullB.spotLink || '')
          : (fullB.spotLink || '').localeCompare(fullA.spotLink || '');
      } else if (sortColumn === 'ancreLink') {
        return sortDirection === 'asc'
          ? (fullA.ancreLink || '').localeCompare(fullB.ancreLink || '')
          : (fullB.ancreLink || '').localeCompare(fullA.ancreLink || '');
      } else if (sortColumn === 'inseredLink') {
        return sortDirection === 'asc'
          ? (fullA.insertionLink || '').localeCompare(fullB.insertionLink || '')
          : (fullB.insertionLink || '').localeCompare(
            fullA.insertionLink || ''
          );
      }
      return 0;
    });
    if (statusSortedItems !== null) {
      const sorted2 = [...statusSortedItems].sort((fullA, fullB) => {
        const a = fullA.netlinking;
        const b = fullB.netlinking;
        if (sortColumn === 'domain') {
          return sortDirection === 'asc'
            ? a.domain.localeCompare(b.domain)
            : b.domain.localeCompare(a.domain);
        } else if (sortColumn === 'languageId') {
          return sortDirection === 'asc'
            ? a.languageId - b.languageId
            : b.languageId - a.languageId;
        } else if (sortColumn === 'themesList') {
          return sortDirection === 'asc'
            ? (a.themesList || '').localeCompare(b.themesList || '')
            : (b.themesList || '').localeCompare(a.themesList || '');
        } else if (sortColumn === 'topicsList') {
          return sortDirection === 'asc'
            ? (a.topicsList || '').localeCompare(b.topicsList || '')
            : (b.topicsList || '').localeCompare(a.topicsList || '');
        } else if (sortColumn === 'contact') {
          return sortDirection === 'asc'
            ? (getEmailPart(a.contact) || '').localeCompare(
              getEmailPart(b.contact) || ''
            )
            : (getEmailPart(b.contact) || '').localeCompare(
              getEmailPart(a.contact) || ''
            );
        } else if (sortColumn === 'contactName') {
          return sortDirection === 'asc'
            ? (getNamePart(a.contact) || '').localeCompare(
              getNamePart(b.contact) || ''
            )
            : (getNamePart(b.contact) || '').localeCompare(
              getNamePart(a.contact) || ''
            );
        } else if (sortColumn === 'contactedAt') {
          return sortDirection === 'asc'
            ? (a.contactedTime || '').localeCompare(b.contactedTime || '')
            : (b.contactedTime || '').localeCompare(a.contactedTime || '');
        } else if (sortColumn === 'kwPosition') {
          return sortDirection === 'asc'
            ? (a.kwPositioned || 0) - (b.kwPositioned || 0)
            : (b.kwPositioned || 0) - (a.kwPositioned || 0);
        } else if (sortColumn === 'Trafic') {
          return sortDirection === 'asc'
            ? (a.trafic || 0) - (b.trafic || 0)
            : (b.trafic || 0) - (a.trafic || 0);
        } else if (sortColumn === 'tF') {
          return sortDirection === 'asc'
            ? (a.tF || 0) - (b.tF || 0)
            : (b.tF || 0) - (a.tF || 0);
        } else if (sortColumn === 'cF') {
          return sortDirection === 'asc'
            ? (a.cF || 0) - (b.cF || 0)
            : (b.cF || 0) - (a.cF || 0);
        } else if (sortColumn === 'tarifHr') {
          return sortDirection === 'asc'
            ? (a.tarifHr || 0) - (b.tarifHr || 0)
            : (b.tarifHr || 0) - (a.tarifHr || 0);
        } else if (sortColumn === 'tarifRc') {
          return sortDirection === 'asc'
            ? (a.tarifRc || 0) - (b.tarifRc || 0)
            : (b.tarifRc || 0) - (a.tarifRc || 0);
        } else if (sortColumn === 'platformRC') {
          return sortDirection === 'asc'
            ? (a.platformRC || 0) - (b.platformRC || 0)
            : (b.platformRC || 0) - (a.platformRC || 0);
        } else if (sortColumn === 'platformHR') {
          return sortDirection === 'asc'
            ? (a.platformHR || 0) - (b.platformHR || 0)
            : (b.platformHR || 0) - (a.platformHR || 0);
        } else if (sortColumn === 'redacResponsible') {
          return sortDirection === 'asc'
            ? (fullA.responsibleRedaction || '').localeCompare(
              fullB.responsibleRedaction || ''
            )
            : (fullB.responsibleRedaction || '').localeCompare(
              fullA.responsibleRedaction || ''
            );
        } else if (sortColumn === 'publicationResponsible') {
          return sortDirection === 'asc'
            ? (fullA.responsiblePublication || '').localeCompare(
              fullB.responsiblePublication || ''
            )
            : (fullB.responsiblePublication || '').localeCompare(
              fullA.responsiblePublication || ''
            );
        } else if (sortColumn === 'redactionState') {
          return sortDirection === 'asc'
            ? (fullA.redactionStatus || '').localeCompare(
              fullB.redactionStatus || ''
            )
            : (fullB.redactionStatus || '').localeCompare(
              fullA.redactionStatus || ''
            );
        } else if (sortColumn === 'publicationState') {
          return sortDirection === 'asc'
            ? (fullA.publicationStatus || '').localeCompare(
              fullB.publicationStatus || ''
            )
            : (fullB.publicationStatus || '').localeCompare(
              fullA.publicationStatus || ''
            );
        } else if (sortColumn === 'programationDate') {
          return sortDirection === 'asc'
            ? (fullA.programmationDate || '').localeCompare(
              fullB.programmationDate || ''
            )
            : (fullB.programmationDate || '').localeCompare(
              fullA.programmationDate || ''
            );
        } else if (sortColumn === 'publicationDate') {
          return sortDirection === 'asc'
            ? (fullA.publicationDate || '').localeCompare(
              fullB.publicationDate || ''
            )
            : (fullB.publicationDate || '').localeCompare(
              fullA.publicationDate || ''
            );
        } else if (sortColumn === 'contactDate') {
          return sortDirection === 'asc'
            ? (fullA.contactDate || '').localeCompare(fullB.contactDate || '')
            : (fullB.contactDate || '').localeCompare(fullA.contactDate || '');
        } else if (sortColumn === 'articleLink') {
          return sortDirection === 'asc'
            ? (fullA.articleLink || '').localeCompare(fullB.articleLink || '')
            : (fullB.articleLink || '').localeCompare(fullA.articleLink || '');
        } else if (sortColumn === 'spotLink') {
          return sortDirection === 'asc'
            ? (fullA.spotLink || '').localeCompare(fullB.spotLink || '')
            : (fullB.spotLink || '').localeCompare(fullA.spotLink || '');
        } else if (sortColumn === 'ancreLink') {
          return sortDirection === 'asc'
            ? (fullA.ancreLink || '').localeCompare(fullB.ancreLink || '')
            : (fullB.ancreLink || '').localeCompare(fullA.ancreLink || '');
        } else if (sortColumn === 'inseredLink') {
          return sortDirection === 'asc'
            ? (fullA.insertionLink || '').localeCompare(
              fullB.insertionLink || ''
            )
            : (fullB.insertionLink || '').localeCompare(
              fullA.insertionLink || ''
            );
        }
        return 0;
      });
      setstatusSortedItems(sorted2);
    }

    setSortedItems(sorted);
  }, [shownNetlinkingFilteredList, sortColumn, sortDirection]);

  useEffect(() => {
    setCurrentPage(1);
  }, [nameSearch, selectedStatus]);

  const filterRedacStatusMenuList = [
    {
      text: t('à lancer admin ou super admin'),
      status: PIXLINK_REDAC_STATUS.TO_BE_LAUNCHED,
    },
    {
      text: t('en cours de rédaction'),
      status: PIXLINK_REDAC_STATUS.REDACTION_IN_PROGRESS,
    },
    {
      text: t('à valider super admin'),
      status: PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_SUPER_ADMIN,
    },
    (campaignType && {
      text: t('à valider client '),
      status: PIXLINK_REDAC_STATUS.TO_BE_VALIDTED_CLIENT,
    }),
    {
      text: t('validé client, à publier'),
      status: PIXLINK_REDAC_STATUS.TO_BE_PUBLISHED,
    },
    { text: t('publié'), status: PIXLINK_REDAC_STATUS.PUBLISHED },
    { text: t('à modifier'), status: PIXLINK_REDAC_STATUS.TO_BE_MODIFIED },
    {
      text: t('en cours de repasse'),
      status: PIXLINK_REDAC_STATUS.REPASSING_IN_PROGRESS,
    },
  ];
  useEffect(() => {
    let NetlinkingColorFilterArray = [];
    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_VALIDATED_PIXALIONE)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList.filter(
          (obj) =>
            obj.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_ADMIN ||
            obj.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_CONSULTANT
        ),
        ...NetlinkingColorFilterArray,
        ,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList.filter(
          (obj) => obj.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
        ),

        ...NetlinkingColorFilterArray,
      ];
    }

    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList.filter(
          (obj) => obj.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT
        ),

        ...NetlinkingColorFilterArray,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_COMMANDED)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList.filter(
          (obj) => obj.spotStatus === PIXLINK_STATUS.TO_BE_COMMANDED
        ),
        ...NetlinkingColorFilterArray,
        ,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.REFUSED_PIXALIONE)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList.filter(
          (obj) => obj.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE
        ),
        ...NetlinkingColorFilterArray,
        ,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.REFUSED_CLIENT)) {
      NetlinkingColorFilterArray = [
        ...shownNetlinkingFilteredList.filter(
          (obj) => obj.spotStatus === PIXLINK_STATUS.REFUSED_CLIENT
        ),

        ...NetlinkingColorFilterArray,
      ];
    }
    if (selectedStatus.includes('') && sortedItems.length > 0) {
      NetlinkingColorFilterArray = sortedItems;
    }
    let filteredArray = NetlinkingColorFilterArray.filter(
      (item) => item !== undefined
    );
    if (
      selectedStatus.length > 0 &&
      !(selectedStatus.length == 1 && selectedStatus.includes(''))
    ) {
      setstatusSortedItems(filteredArray);
    } else if (sortedItems.length > 0) {
      NetlinkingColorFilterArray = sortedItems;
      filteredArray = NetlinkingColorFilterArray.filter(
        (item) => item !== undefined
      );
      setstatusSortedItems(filteredArray);
    } else {
      setstatusSortedItems(sortedItems)
    }
  }, [selectedStatus, sortedItems, shownNetlinkingFilteredList]);

  function updateParentString(itemId, linkType, link) {
    const sortedupdatedItems = sortedItems.map((item) => {
      if (item.netlinking.id === itemId) {
        switch (linkType) {
          case 'articleLink':
            updateSingleItem({ ...item, articleLink: link });
            return { ...item, articleLink: link };
            break;
          case 'spotLink':
            updateSingleItem({ ...item, spotLink: link });
            return { ...item, spotLink: link };
            break;
          case 'ancreLink':
            updateSingleItem({ ...item, ancreLink: link });
            return { ...item, ancreLink: link };
            break;
          case 'inseredLink':
            updateSingleItem({ ...item, insertionLink: link });
            return { ...item, insertionLink: link };
          default:
            return item;
        }

        // Update the status property of the item
      }
      return item;
    });
    setSortedItems(sortedupdatedItems);
    sendUpdateSaveSelectedNetlinking(sortedupdatedItems);
  }
  const handleLongStringEnter = (event, longString) => {
    event.target.textContent = longString;
  };

  const handleLongStringLeave = (event, longString) => {
    if (longString !== null && longString !== undefined && longString.length > 20) {
      event.target.textContent = longString.slice(0, 20) + '...'
    } else if (longString !== null && longString !== undefined && longString.length > 0) {
      event.target.textContent = longString;
    } else {
      event.target.textContent = '-'
    }
  };

  function updateDate(itemId, dateType, date) {
    const sortedupdatedItems = sortedItems.map((item) => {
      if (item.netlinking.id === itemId) {
        switch (dateType) {
          case 'programmationDate':
            updateSingleItem({ ...item, programmationDate: date });
            return { ...item, programmationDate: date };
            break;
          case 'publicationDate':
            updateSingleItem({ ...item, publicationDate: date });
            return { ...item, publicationDate: date };
            break;
          case 'contactDate':
            updateSingleItem({ ...item, contactDate: date });
            return { ...item, contactDate: date };
            break;
          default:
            return item;
        }

        // Update the status property of the item
      }
      return item;
    });
    setSortedItems(sortedupdatedItems);
    sendUpdateSaveSelectedNetlinking(sortedupdatedItems);
  }

  const updateSingleItem = async (item) => {
    const itemUpdated = {
      netlinking: item.netlinking,
      responsibleRedaction: item.responsibleRedaction,
      responsiblePublication: item.responsiblePublication,
      programmationDate: item.programmationDate,
      publicationDate: item.publicationDate,
      contactDate: item.contactDate,
      articleLink: item.articleLink,
      spotLink: item.spotLink,
      ancreLink: item.ancreLink,
      insertionLink: item.insertionLink,
      publicationStatus:
        item.publicationStatus === ''
          ? PIXLINK_PUBLISH_STATUS.TO_BE_COMMANDED
          : item.publicationStatus,
      redactionStatus:
        item.redactionStatus === ''
          ? PIXLINK_REDAC_STATUS.TO_BE_LAUNCHED
          : item.redactionStatus,
      spotStatus: item.spotStatus,
    };
    try {
      const response = await axiosPixLink.post(
        `/campaign/UpdateSingleSelectedNetlinking/${id}`,
        itemUpdated
      );
      // Handle success, reset form if needed
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };

  function updateResponsible(itemId, ResponsibleType, value) {
    const sortedupdatedItems = sortedItems.map((item) => {
      if (item.netlinking.id === itemId) {
        switch (ResponsibleType) {
          case 'responsibleRedaction':
            updateSingleItem({ ...item, responsibleRedaction: value });
            return { ...item, responsibleRedaction: value };
            break;
          case 'responsiblePublication':
            updateSingleItem({ ...item, responsiblePublication: value });
            return { ...item, responsiblePublication: value };
            break;
          default:
            return item;
        }

        // Update the status property of the item
      }
      return item;
    });
    setSortedItems(sortedupdatedItems);
    sendUpdateSaveSelectedNetlinking(sortedupdatedItems);
  }

  function getRedactStatusText(status) {
    const item = filterRedacStatusMenuList.find(
      (item) => item.status === status
    );
    return item ? item.text : 'à lancer admin ou super admin';
  }

  function getPublishStatusText(status) {
    const item = filterPublishStatusMenuList.find(
      (item) => item.status === status
    );
    return item ? item.text : 'à commander';
  }

  const filterPublishStatusMenuList = [
    {
      text: t('à commander'),
      status: PIXLINK_PUBLISH_STATUS.TO_BE_COMMANDED,
    },
    {
      text: t('commandé, en attente de retour'),
      status: PIXLINK_PUBLISH_STATUS.COMMAND_IN_PROGRESS,
    },
    {
      text: t('à valider super admin'),
      status: PIXLINK_PUBLISH_STATUS.TO_BE_VALIDTED_SUPER_ADMIN,
    },
    {
      text: t('à modifier'),
      status: PIXLINK_PUBLISH_STATUS.TO_BE_MODIFIED,
    },
    {
      text: t('publié'),
      status: PIXLINK_PUBLISH_STATUS.PUBLISHED,
    },
    { text: t('programmé'), status: PIXLINK_PUBLISH_STATUS.PROGRAMMED },
    {
      text: t('refusé éditeur'),
      status: PIXLINK_PUBLISH_STATUS.REFUSED_EDITOR,
    },
  ];

  const handleSortToggle = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleThemesListHover = (event, themes) => {
    event.target.textContent = themes;
  };

  const handleThemesListLeave = (event, themes) => {
    if (themes !== null && themes !== undefined && themes.includes(',')) {
      event.target.textContent = themes.split(',')[0] + '...';
    } else {
      event.target.textContent = themes;
    }
  };

  function netlinkingIsChosen(item) {
    // Check if the item is present in shownNetlinkingFilteredList
    return shownNetlinkingFilteredList.some(
      (chosenItem) => chosenItem.netlinking.id === item.netlinking.id
    );
  }

  function netlinkingIsChosenColor(item) {
    return item.spotStatus;
  }

  const transformDate = (dateString) => {
    const parsedDate = new Date(dateString);
    const year = parsedDate.getFullYear();
    const month = ('0' + (parsedDate.getMonth() + 1)).slice(-2);
    const day = ('0' + parsedDate.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  };

  function getEmailPart(inputString) {
    try {
      // Split the inputString using '<' and '>' as delimiters
      const parts = inputString.split('<');

      // Check if the split produced at least two parts
      if (parts.length >= 2) {
        // Get the second part and remove the trailing '>'
        const emailPart = parts[1].replace('>', '').trim();
        return emailPart;
      } else {
        // If the inputString is not in the expected format, return null or an appropriate value
        return inputString;
      }
    } catch (err) {
      console.error('An error occurred:', err.message);
      return '-';
    }
  }

  function getNamePart(inputString) {
    try {
      // Split the inputString using '@' as a delimiter
      const emailRegex = /[\w.-]+@[\w.-]+\.[A-Za-z]{2,}/g;
      let stringWithoutEmail = inputString.replace(emailRegex, '');
      stringWithoutEmail = stringWithoutEmail.replace('>', '');
      stringWithoutEmail = stringWithoutEmail.replace('<', '');
      stringWithoutEmail = stringWithoutEmail.replace('/', '');
      if (stringWithoutEmail.trim().length === 0) {
        return '-';
      }
      return stringWithoutEmail.trim();
    } catch (err) {
      console.error('An error occurred:', err.message);
      return '-';
    }
  }

  const handleNetlinkingContactFilterChange = (value) => {
    // Call the callback function from the Father component to pass the value to the parent
    onNetlinkingContactFilterChange(value);
  };

  const handleClickResponsibles = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleCloseResponsibles = () => {
    setAnchorEl(null);
  };

  const handleClickeRedacStatusFilter = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClickePulicStatusFilter = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClickStatusRedacFilter = (status) => {
    const sortedupdatedItems = sortedItems.map((item) => {
      if (item.netlinking.id === itemIdToBeChanged) {
        updateSingleItem({ ...item, redactionStatus: status });
        return { ...item, redactionStatus: status };
      }
      return item;
    });
    setSortedItems(sortedupdatedItems);
    sendUpdateSaveSelectedNetlinking(sortedupdatedItems);
    handleCloseRedacPulicStatusFilter();
    //  setSelectedStatus(status);
  };
  const handleClickStatusPublicFilter = (status) => {
    const sortedupdatedItems = sortedItems.map((item) => {
      if (item.netlinking.id === itemIdToBeChanged) {
        // Update the status property of the item
        updateSingleItem({ ...item, publicationStatus: status });
        return { ...item, publicationStatus: status };
      }
      return item;
    });
    setSortedItems(sortedupdatedItems);
    sendUpdateSaveSelectedNetlinking(sortedupdatedItems);
    handleCloseRedacPulicStatusFilter();
    //  setSelectedStatus(status);
  };
  const handleCloseRedacPulicStatusFilter = () => {
    setAnchorEl(null);
  };
  const history = useHistory();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = sortColumn == "" ? (
    statusSortedItems !== null
      ? statusSortedItems
        .filter((item) =>
          item.netlinking.domain
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        ).sort((a, b) => a.netlinking.domain.localeCompare(b.netlinking.domain))
        .slice(indexOfFirstItem, indexOfLastItem)
      : sortedItems
        .filter((item) =>
          item.netlinking.domain
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        ).sort((a, b) => a.netlinking.domain.localeCompare(b.netlinking.domain))
        .slice(indexOfFirstItem, indexOfLastItem)) :
    (statusSortedItems !== null
      ? statusSortedItems
        .filter((item) =>
          item.netlinking.domain
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        )
        .slice(indexOfFirstItem, indexOfLastItem)
      : sortedItems
        .filter((item) =>
          item.netlinking.domain
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        )
        .slice(indexOfFirstItem, indexOfLastItem));

  return (
    <>
      <div className="table-responsive">
        <div className="box-table-netlinking">
          <table className="table-lists">
            <thead>
              <tr>
                {columns.find((column) => column.name === 'domain')
                  ?.visible && (
                    <th
                      className="cell-fixed-column"
                      onClick={() => handleSortToggle('domain')}
                    >
                      <span className={sortColumn === 'domain' ? 'active' : ''}>
                        {t('Domaine')}
                      </span>
                      {sortColumn === 'domain' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px', marginTop: '5px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px', marginTop: '5px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {/*----------------------------------------------------------------------------------------------------------------------------------------------*/}
                {columns.find((column) => column.name === 'redacResponsible')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('redacResponsible')}
                      className={
                        sortColumn === 'redacResponsible' ? 'active' : ''
                      }
                    >
                      {t('Resp. rédac')}
                      {sortColumn === 'redacResponsible' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find(
                  (column) => column.name === 'publicationResponsible'
                )?.visible && (
                    <th
                      onClick={() => handleSortToggle('publicationResponsible')}
                      className={
                        sortColumn === 'publicationResponsible' ? 'active' : ''
                      }
                    >
                      {t('Resp. publi')}
                      {sortColumn === 'publicationResponsible' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {columns.find((column) => column.name === 'redactionState')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('redactionState')}
                      className={sortColumn === 'redactionState' ? 'active' : ''}
                    >
                      {t('Etat')} <br></br>
                      {t('rédaction')}{' '}
                      {sortColumn === 'redactionState' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {columns.find((column) => column.name === 'publicationState')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('publicationState')}
                      className={
                        sortColumn === 'publicationState' ? 'active' : ''
                      }
                    >
                      {t('Etat')}
                      <br></br> {t('publication estimé')}
                      {sortColumn === 'publicationState' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {columns.find((column) => column.name === 'programationDate')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('programationDate')}
                      className={
                        sortColumn === 'programationDate' ? 'active' : ''
                      }
                    >
                      {t('Date')}
                      <br></br>
                      {t('programmation')}
                      {sortColumn === 'programationDate' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {columns.find((column) => column.name === 'publicationDate')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('publicationDate')}
                      className={sortColumn === 'publicationDate' ? 'active' : ''}
                    >
                      {t('Date')}
                      <br></br>
                      {t('publication estimé')}
                      {sortColumn === 'publicationDate' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {columns.find((column) => column.name === 'contactDate')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('contactDate')}
                      className={sortColumn === 'contactDate' ? 'active' : ''}
                    >
                      {t('Contacté le')}
                      {sortColumn === 'contactDate' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find((column) => column.name === 'articleLink')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('articleLink')}
                      className={sortColumn === 'articleLink' ? 'active' : ''}
                    >
                      {t('Lien')} <br></br>
                      {'article'}
                      {sortColumn === 'articleLink' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {columns.find((column) => column.name === 'spotLink')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('spotLink')}
                      className={sortColumn === 'spotLink' ? 'active' : ''}
                    >
                      {t('Lien')} <br></br>
                      {t('du spot')}
                      {sortColumn === 'spotLink' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {columns.find((column) => column.name === 'ancreLink')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('ancreLink')}
                      className={sortColumn === 'ancreLink' ? 'active' : ''}
                    >
                      {t('Ancre')}
                      <br></br> {t('de lien')}
                      {sortColumn === 'ancreLink' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {columns.find((column) => column.name === 'inseredLink')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('inseredLink')}
                      className={sortColumn === 'inseredLink' ? 'active' : ''}
                    >
                      {t('Lien')} <br></br>
                      {t('à insérer')}
                      {sortColumn === 'inseredLink' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {/*----------------------------------------------------------------------------------------------------------------------------------------------*/}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id} style={{ cursor: 'pointer' }}>
                  {columns.find((column) => column.name === 'domain')
                    ?.visible && (
                      <td className="cell-fixed-column">
                        {item.unsureSpot === true && (<img width="30px" height="30px" src="Images/icon-danger.png" alt="icon-danger" className="danger-icon" />)}
                        {isIdInArray(
                          item?.netlinking?.id,
                          historicBackLinkings
                        ) && (
                            <span style={{ marginLeft: '5px' }}>
                              <img
                                style={{ width: '20px', cursor: 'pointer' }}
                                src="Images/icon-recyclage.png"
                                alt="icon-navigate"
                              />
                            </span>
                          )}
                        {isIdInArray(item?.netlinking?.id, wantedNetlinkings) && (
                          <span style={{ marginLeft: '5px' }}>
                            <img
                              style={{
                                width: '15px',
                                cursor: 'pointer',
                                marginBottom: '5px',
                              }}
                              src="Images/icon-star.png"
                              alt="icon-navigate"
                            />
                          </span>
                        )}
                        <a
                          href={
                            !item.netlinking.domain.startsWith('http://') &&
                              !item.netlinking.domain.startsWith('https://')
                              ? 'https://' + item.netlinking.domain
                              : item.domain
                          }
                          target="_blank"
                          className={(() => {
                            switch (netlinkingIsChosenColor(item)) {
                              case 'TO_BE_VALIDATED_SUPER_ADMIN':
                                return 'domain-container gras-grey-row';
                              case 'TO_BE_VALIDATED_SUPER_CONSULTANT':
                                return 'domain-container black-row';
                              case 'TO_BE_VALIDATED_CLIENT':
                                return 'domain-container orange-row';
                              case 'TO_BE_FILTERED_CONSULTANT':
                                return 'domain-container blue-row';
                              case 'TO_BE_COMMANDED':
                                return 'domain-container green-row';
                              case 'REFUSED_CLIENT':
                                return 'domain-container red-row';
                              case 'REFUSED_PIXALIONE':
                                return 'domain-container violet-row';

                              default:
                                return 'domain-container grey-row'; // default class if netlinkingIsChosen(item) is neither true nor false
                            }
                          })()}
                        >
                          {item.netlinking.domain}
                        </a>
                      </td>
                    )}
                  {/*************************************************************** */}
                  {columns.find((column) => column.name === 'redacResponsible')
                    ?.visible && (
                      <td onClick={() => { }}>
                        <div>
                          {[
                            {
                              firstName: 'redac',
                              lastName: 'Responsible',
                              email: 'redaResponsible@gmail.com',
                            },
                          ].length === 0 ? (
                            <Button disabled>{t('0 Consultants')} </Button>
                          ) : (
                            <>
                              {item.responsibleRedaction ? (
                                <img
                                  src={getIconUrl(item.responsibleRedaction)} // Set the src to the icon URL
                                  alt={item.responsibleRedaction} // Alt text as the option name
                                  style={{
                                    marginRight: '10px',
                                    width: '20px',
                                    height: '20px',
                                  }} // Styling for the icon
                                />
                              ) : (
                                <img
                                  src="/Images/icon-users-down.png"
                                  alt="icon-users-down"
                                />
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    )}
                  {columns.find(
                    (column) => column.name === 'publicationResponsible'
                  )?.visible && (
                      <td onClick={() => { }}>
                        <div>
                          {[
                            {
                              firstName: 'redac',
                              lastName: 'Responsible',
                              email: 'redaResponsible@gmail.com',
                            },
                          ].length === 0 ? (
                            <Button disabled>{t('0 Consultants')} </Button>
                          ) : (
                            <>
                              {item.responsiblePublication ? (
                                <img
                                  src={getIconUrl(item.responsiblePublication)} // Set the src to the icon URL
                                  alt={item.responsiblePublication} // Alt text as the option name
                                  style={{
                                    marginRight: '10px',
                                    width: '20px',
                                    height: '20px',
                                  }} // Styling for the icon
                                />
                              ) : (
                                <img
                                  src="/Images/icon-users-down.png"
                                  alt="icon-users-down"
                                />
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    )}
                  {columns.find((column) => column.name === 'redactionState')
                    ?.visible && (
                      <td style={{ cursor: 'default' }} onClick={() => { }}>
                        <span>
                          <button
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={
                              (e) => {
                                setItemIdToBeChanged(item.netlinking.id);
                                handleClickeRedacStatusFilter(-1, e);
                              } // Pass 'item' here
                            }
                            style={{
                              padding: '5px',
                              border: 'none',
                              background: 'none',
                            }}
                          >
                            {item?.redactionStatus ? (
                              <span>
                                {getRedactStatusText(item?.redactionStatus)}
                              </span>
                            ) : (
                              <span> {t('à lancer admin ou super admin')}</span>
                            )}
                          </button>
                          <CustomDropdownSingleRedactionPublicationSpotDisabled
                            selectedStatus={
                              item?.redactionStatus
                                ? item?.redactionStatus
                                : PIXLINK_REDAC_STATUS.TO_BE_LAUNCHED
                            }
                            handleClose={handleCloseRedacPulicStatusFilter}
                            anchorEl={anchorEl && anchorEl['-1']}
                            list={filterRedacStatusMenuList}
                            toggleStatus={(status) => {
                              handleClickStatusRedacFilter(status); // Use 'item' here
                            }}
                            getOptionLabel={(option) => {
                              return option.text;
                            }}
                          />
                        </span>
                      </td>
                    )}
                  {columns.find((column) => column.name === 'publicationState')
                    ?.visible && (
                      <td style={{ cursor: 'default' }} onClick={() => { }}>
                        {
                          <span>
                            <button
                              aria-controls="customized-menu"
                              aria-haspopup="true"
                              onClick={(e) => {
                                setItemIdToBeChanged(item.netlinking.id);
                                handleClickePulicStatusFilter(-2, e);
                              }}
                              style={{
                                padding: '5px',
                                border: 'none',
                                background: 'none',
                              }}
                            >
                              {item?.publicationStatus ? (
                                <span>
                                  {getPublishStatusText(item?.publicationStatus)}{' '}
                                </span>
                              ) : (
                                <span> {t('à commander')}</span>
                              )}
                            </button>
                            <CustomDropdownSingleRedactionPublicationSpotDisabled
                              selectedStatus={
                                item?.publicationStatus
                                  ? item?.publicationStatus
                                  : PIXLINK_PUBLISH_STATUS.TO_BE_COMMANDED
                              }
                              handleClose={handleCloseRedacPulicStatusFilter}
                              anchorEl={anchorEl && anchorEl['-2']}
                              list={filterPublishStatusMenuList}
                              toggleStatus={(status) =>
                                handleClickStatusPublicFilter(status)
                              }
                              getOptionLabel={(option) => {
                                return option.text;
                              }}
                            />
                          </span>
                        }
                      </td>
                    )}{' '}
                  {columns.find((column) => column.name === 'programationDate')
                    ?.visible && (
                      <td style={{ cursor: 'default' }} onClick={() => { }}>
                        <span>
                          {item.programmationDate ? (
                            <input
                              disabled={true}
                              value={
                                item.programmationDate
                                  ? item.programmationDate.slice(0, 10)
                                  : ''
                              }
                              onChange={(event) => {
                                updateDate(
                                  item.netlinking.id,
                                  'programmationDate',
                                  event.target.value
                                );
                              }}
                              style={{
                                padding: '10px',
                                border: '2px solid #ccc',
                                borderRadius: '5px',
                                fontFamily: 'Arial, sans-serif',
                                fontSize: '16px',
                                color: '#333',
                                backgroundColor: '#FFF',
                                height: '45px',
                                width: item.programmationDate
                                  ? '150px'
                                  : detectBrowser() === browserList.FIREFOX
                                    ? '150px'
                                    : '120px',
                              }}
                              type="date"
                            ></input>
                          ) : (
                            <DisabledSmallCalendarIcon />
                          )}
                        </span>
                      </td>
                    )}
                  {columns.find((column) => column.name === 'publicationDate')
                    ?.visible && (
                      <td style={{ cursor: 'default' }} onClick={() => { }}>
                        <span>
                          {item.publicationDate ? (
                            <input
                              disabled={true}
                              value={
                                item.publicationDate
                                  ? item.publicationDate.slice(0, 10)
                                  : ''
                              }
                              onChange={(event) => {
                                updateDate(
                                  item.netlinking.id,
                                  'publicationDate',
                                  event.target.value
                                );
                              }}
                              style={{
                                padding: '10px',
                                marginLeft: '1px',
                                border: '2px solid #ccc',
                                borderRadius: '5px',
                                fontFamily: 'Arial, sans-serif',
                                fontSize: '16px',
                                color: '#333',
                                backgroundColor: '#FFF',
                                width: item.publicationDate
                                  ? '150px'
                                  : detectBrowser() === browserList.FIREFOX
                                    ? '150px'
                                    : '120px',
                              }}
                              type="date"
                            ></input>
                          ) : (
                            <DisabledSmallCalendarIcon />
                          )}
                        </span>
                      </td>
                    )}
                  {columns.find((column) => column.name === 'contactDate')
                    ?.visible && (
                      <td style={{ cursor: 'default' }} onClick={() => { }}>
                        <span>
                          {item.contactDate ? (
                            <input
                              disabled={true}
                              value={
                                item.contactDate
                                  ? item.contactDate.slice(0, 10)
                                  : ''
                              }
                              onChange={(event) => {
                                updateDate(
                                  item.netlinking.id,
                                  'contactDate',
                                  event.target.value
                                );
                              }}
                              style={{
                                padding: '10px',
                                marginLeft: '1px',
                                border: '2px solid #ccc',
                                borderRadius: '5px',
                                fontFamily: 'Arial, sans-serif',
                                fontSize: '16px',
                                color: '#333',
                                backgroundColor: '#FFF',
                                width: item.contactDate
                                  ? '150px'
                                  : detectBrowser() === browserList.FIREFOX
                                    ? '150px'
                                    : '120px',
                              }}
                              type="date"
                            ></input>
                          ) : (
                            <DisabledSmallCalendarIcon />
                          )}
                        </span>
                      </td>
                    )}

                  {columns.find((column) => column.name === 'articleLink')
                    ?.visible && (
                      <td onClick={() => { }}>
                        <span>
                          <LinkIconWithInputShownDisabled
                            id={
                              item?.netlinking?.id
                                ? item?.netlinking?.id
                                : item.id
                            }
                            value={item.articleLink}
                            column="articleLink"
                            updateParentString={updateParentString}
                          ></LinkIconWithInputShownDisabled>
                        </span>
                      </td>
                    )}{' '}
                  {columns.find((column) => column.name === 'spotLink')
                    ?.visible && (
                      <td onClick={() => { }}>
                        <span>
                          {' '}
                          <LinkIconWithInputShownDisabled
                            id={
                              item?.netlinking?.id
                                ? item?.netlinking?.id
                                : item.id
                            }
                            column="spotLink"
                            value={item.spotLink}
                            updateParentString={updateParentString}
                          ></LinkIconWithInputShownDisabled>
                        </span>
                      </td>
                    )}

                  {columns.find((column) => column.name === 'ancreLink')
                    ?.visible && (
                      <td onClick={() => { }}>
                        <span
                          onMouseOver={(event) =>
                            handleLongStringEnter(event, item.ancreLink)
                          }
                          onMouseLeave={(event) =>
                            handleLongStringLeave(event, item.ancreLink)
                          }>{item.ancreLink
                            ? item.ancreLink.length > 20
                              ? `${item.ancreLink.substring(0, 20)}...`
                              : item.ancreLink
                            : '-'}</span>
                      </td>
                    )}
                  {columns.find((column) => column.name === 'inseredLink')
                    ?.visible && (
                      <td onClick={() => { }}>
                        <span
                          onMouseOver={(event) =>
                            handleLongStringEnter(event, item.insertionLink)
                          }
                          onMouseLeave={(event) =>
                            handleLongStringLeave(event, item.insertionLink)
                          }>{item.insertionLink
                            ? item.insertionLink.length > 20
                              ? `${item.insertionLink.substring(0, 20)}...`
                              : item.insertionLink
                            : '-'}</span>
                      </td>
                    )}{' '}
                  {/*************************************************************** */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="box-pagination">
        {sortedItems.length === 0 || currentPage === 1 ? (
          <div style={{ cursor: 'not-allowed' }} className="text-page">
            {t('Précédent')}
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            {t('Précédent')}
          </div>
        )}
        <div className="list-page-number">
          {sortedItems.length === 0 ||
            currentPage === Math.ceil(sortedItems.length / itemsPerPage) ? (
            <span style={{ cursor: 'not-allowed' }} className="text-page">
              {t('Suivant')}
            </span>
          ) : (
            <span onClick={() => setCurrentPage(currentPage + 1)}>
              {t('Suivant')}
            </span>
          )}
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          setErrorSuccess(false);
          //       history.goBack();
        }}
        success={isSuccess}
      />
      <ConfirmationModal
        open={confirmationDeleteForce}
        message={confirmationMsgDeleteForce}
        handleClose={() => setConfirmationDeleteForce(false)}
        executeAction={() => {
          removeNetlinkingId(
            itemTobeDeleted.map((item) => {
              if (item.netlinking !== undefined) {
                return item;
              } else {
                return {
                  id: null,
                  netlinking: {
                    id: item?.id,
                    domain: item?.domain,
                    kwPositioned: item?.kwPositioned,
                    trafic: item?.trafic,
                    tF: item?.tF,
                    cF: item?.cF,
                    tarifHr: item?.tarifHr,
                    conditions: item?.conditions,
                    themesList: item?.themesList,
                    topicsList: item?.topicsList,
                    platformName: item?.platformName,
                    platformPrice: item?.platformPrice,
                    platformRC: item?.platformRC,
                    platformHR: item?.platformHR,
                    contactPhoneNumber: item?.contactPhoneNumber,
                    languageId: item?.languageId,
                    contact: item?.contact,
                    tarifRc: item?.tarifRc,
                    contactedTime: item?.contactedTime,
                  },
                  responsibleRedactio: null,

                  responsiblePublication: null,
                  programmationDate: null,
                  publicationDate: null,
                  contactDate: null,
                  articleLink: null,
                  spotLink: null,
                  ancreLink: null,
                  insertionLink: null,
                  publicationStatus: null,
                  redactionStatus: null,
                  spotStatus: null,
                };
              }
            })
          );
          setConfirmationDeleteForce(false);
        }}
      />
    </>
  );
}
