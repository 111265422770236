import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import StillWorkingOnIt from './UnderConstruction';
import EditorProfile from 'pages/Editor/EditorProfile';
import { EditorHomePage } from 'pages/Editor/EditorHomepage';

export default class EditorMainMenu extends React.Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route exact path="/Editor" component={EditorHomePage} />
          <Route path="/Editor/MainMenu" component={EditorHomePage} />
          <Route path="/Editor/MonProfil" component={EditorProfile} />

          <Route exact path="/Editor/CampagnesEnCour" component={StillWorkingOnIt} />
          <Route path="/Editor/CampagnesEnRetard" component={StillWorkingOnIt} />
          <Route path="/Editor/CampagnesEnLigne" component={StillWorkingOnIt} />
          <Route path="/Editor/LiensSupprimé" component={StillWorkingOnIt} />
        </Switch>
      </Layout>
    );
  }
}
