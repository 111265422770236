import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axiosPrivate from 'config/axiosPrivate';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { lower } from 'utils/StringUtils';
import { useHistoryState } from 'utils/hooks/useHistoryState';

import { adminOrderListSelector } from 'store/Admin/OrderList';
import { adminClientListSelector } from 'store/Admin/ClientList';

import { fetchClientList } from 'store/Admin/ClientList/features';
import { fetchAllOrders } from 'store/Admin/OrderList/features';
import { removeClient } from 'store/Admin/ClientList';

import { Loader } from 'components/shared/Loader';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';

import './Homepage.css';
import axiosPixLink from 'config/axiosPixLink';
import ClientOrders from './ClientOrders';
import { UserSessionSelector } from 'store/UserSession';
const API = {
  deleteWebsite: '/clientWebsite/',
};

export const ClientAllCampaigns = () => {
  let { user } = useSelector(UserSessionSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  let { isLoading } = useSelector(adminOrderListSelector);

  const { t } = useTranslation();
  let { clients, isLoadingClients } = useSelector(adminClientListSelector);

  const [selectedWebsite, setSelectedWebsite] = useState(false);
  const [Clientsclients, setClientsclients] = useState([]);
  const [isLoadinClientsclients, setIsLoadinClientsclients] = useState(false);

  const [urlSearch, setUrlSearch] = useHistoryState('urlSearch', '');
  const [unassignedCampaignsCount, setUnassignedCampaignsCount] = useState(0);
  const [assignedCampaignsCount, setAssignedCampaignsCount] = useState(0);
  const [overdueCampaignsCount, setOverdueCampaignsCount] = useState(0);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  useEffect(() => {
    document.title = t('PixLink - Mon tableau de bord');
    window.dispatchEvent(new Event('locationchange'));
    dispatch(fetchAllOrders());
    dispatch(fetchClientList());
  }, []);
  useEffect(() => {
    setIsLoadinClientsclients(true);
    axiosPixLink
      .post(`/website/findClientsWebsite`, user.email)
      .then((res) => {
        setClientsclients(res.data);
        setIsLoadinClientsclients(false);
      })
      .catch((err) => console.log(err));
    setIsLoadinClientsclients(false);
  });
  useEffect(() => {
    axiosPixLink
      .get(`/campaign/countNotEmptyClients`)
      .then((res) => setAssignedCampaignsCount(res.data))
      .catch((err) => console.log(err));
  });
  useEffect(() => {
    axiosPixLink
      .get(`/campaign/countEmptyClientsOverdue`)
      .then((res) => setOverdueCampaignsCount(res.data))
      .catch((err) => console.log(err));
  });

  const DeleteWebsite = () => {
    axiosPixLink
      .delete(`/website/${selectedWebsite.id}/delete`)
      .then(() => {
        setConfirmation(false);
        dispatch(removeClient(selectedWebsite.id));

        setErrorSuccessMsg(
          `${t('Le contrat de')} ${selectedWebsite.urlSite} ${t(
            'a été supprimé avec succès'
          )} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setConfirmation(false);
        switch (e.response.status) {
          case 500:
            setErrorSuccessMsg(
              t("il y'a des campagnes liés au site web ") +
              selectedWebsite.urlSite
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  return (
    <div>
      <div className="content-center">
        <div className="row-homePage">
          <div className="col-12 col-md-12 mr-12">
            <div>
              <ClientOrders></ClientOrders>
            </div>
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={DeleteWebsite}
      />
    </div>
  );
};
