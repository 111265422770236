import { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosPixLink from 'config/axiosPixLink';
import { capitalizeFirstLetter } from 'utils/StringUtils';

export const PlatformAutoComplete = ({ onPlatformChange, defaultValue }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue || null); // Setting default value from props
  const [platfromsList, setPlatfromsList] = useState([
    'Getfluence',
    'Develink',
    'Rocketlinks',
    'Ereferer'
  ]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <Autocomplete
      value={value}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          height: '40px',
        },
      }}
      disabled={platfromsList.length > 0 ? false : true}
      onChange={(event, newValue) => {
        setValue(newValue);
        onPlatformChange(newValue);
      }}
      options={platfromsList}
      getOptionLabel={(option) => capitalizeFirstLetter(t(`${option}`))} // Adjust 'translationKey' to your actual translation key
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          sx={{ height: '40px' }}
        />
      )}
    />
  );
};
