import { useEffect, useState } from 'react';

import axiosPixLink from 'config/axiosPixLink';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ClientHistoricalBacklinkingsTableControl from 'components/Netlinking/Table/Client/ClientHistoricalBacklinkingsTableControl';
import ClientSelectedSpotTableControl from 'components/Netlinking/Table/Client/ClientSelectedSpotTableControl';
import { MyCustomHighchartsSmall } from 'components/shared/Charts/MyCustomHighChartsSmall';
import { MyCustomHighcharts } from 'components/shared/Charts/MyCustomHighcharts';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  COLOR_JAUGE,
  PIXLINK_CAMPAIGN_STATUS,
  PIXLINK_STATUS,
} from 'utils/Constants';
import { useHistoryState } from 'utils/hooks/useHistoryState';
import {
  calculateClientMinimumLinks,
  calculateMinimumLinks,
} from 'utils/netlinking/linksNumber';
import './order.css';
import { MyCustomReverseHighChats } from 'components/shared/Charts/MyCustomReverseHighChats';
import {
  calculateClientRefusedClient,
  calculateClientToBeCommandedClient,
  calculateClientToBeFilteredClient,
  calculateClientValidateClient,
} from 'utils/Jauge/CalculateClient';
import { forEach } from 'jszip';
import FeedbackModal from 'components/shared/modal/FeedBacksModal';
import { FeedbackModalShow } from 'components/shared/modal/FeedBacksModalShow';
import { SmallCustomTooltip } from 'components/shared/CustomTooltip';

const API = {
  getAvailableThematics: '/netlinking/all-thematics',
  getAvailableLanguages: '/language/all',
  getExistingAccounts: 'website/account/all',
  getAllWriters: '/user/all',
  getAllWebsiteOfAccount: '/website',
  getAllDevisOfAccount: '/user/getDevise',
  updateCampaign: '/campaign/update',
  createSmartOrder: '/commands/addSmartCommand',
  getAllConsultants: '/user/consultants',
  getAllSuperConsultants: 'user/superconsultants',
  getCampaignDetail: '/campaign/campaignDetail/',
};

export default function ClientCampaignSourcing({
  campaignStatus,
  updateCampaignStatus,
  isFeedBackSent,
  campaignType
}) {
  const dispatch = useDispatch();
  const [urlSearch, setUrlSearch] = useHistoryState('urlSearch', '');
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [wantedNetlinkings, setWantedNetlinkings] = useState([]);
  const [historicBackLinkings, setHistoricBackLinkings] = useState([]);
  const [netlinkings, setNetlinkings] = useState([]);
  const [netlinkingsTmp, setNetlinkingsTmp] = useState([]);
  const [netlinkingToExportAddFavorite, setNetlinkingToExportAddFavorite] = useState([]);
  const [nbrOfLinksDemanded, setNbrOfLinksDemanded] = useState(0);
  const [nameSearch, setNameSearch] = useState('');
  const [netlinkingContactFilter, setNetlinkingContactFilter] = useState('');
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [campaignFilter, setCampaignFilter] = useState(null);
  const [isSearchForNetlinkinngOpen, setIsSearchForNetlinkinngOpen] =
    useState(false);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [confirmationSaveForce, setConfirmationSaveForce] = useState(false);
  const [confirmationMsgSaveForce, setConfirmationMsgSaveForce] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [active, setActive] = useState(1);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [showFeedbackModalOpen, setShowFeedbackModalOpen] = useState(false);
  const [isSentFeedBackTmp, setIsSentFeedBackTmp] = useState(false)


  const navItems = [
    { text: t('Spots sélectionnés'), active: 1 },
    { text: t('Historique Backlinks'), active: 2 },
  ];
  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = t('PixLink - Detail campaign');
  }, [t]);

  useEffect(() => {
    axiosPixLink
      .get(
        API.getCampaignDetail +
        `${window.location.href.split('/')[
        window.location.href.split('/').length - 1
        ]
        }`
      )
      .then((res) => {
        setCampaignFilter(res.data);
        setWantedNetlinkings(res.data.wantedNetlinkings);
        setHistoricBackLinkings(res.data.historicBackLinkings);
        setNetlinkingsTmp(res.data.netlinkingSelecteds);
        setNetlinkings(res.data.netlinkingSelecteds);
        setNbrOfLinksDemanded(res.data.linksNumbers);
      });
  }, []);

  function sendNetlinkingChoose(data) {
    const newData = data.map((item) => {
      if (!item.hasOwnProperty('netlinking')) {
        const newSchemaItem = {
          id: null,
          netlinking: {
            id: item?.id,
            domain: item?.domain,
            kwPositioned: item?.kwPositioned,
            trafic: item?.trafic,
            tF: item?.tF,
            cF: item?.cF,
            tarifHr: item?.tarifHr,
            conditions: item?.conditions,
            themesList: item?.themesList,
            topicsList: item?.topicsList,
            platformName: item?.platformName,
            platformPrice: item?.platformPrice,
            platformRC: item?.platformRC,
            platformHR: item?.platformHR,
            contactPhoneNumber: item?.contactPhoneNumber,
            languageId: item?.languageId,
            contact: item?.contact,
            tarifRc: item?.tarifRc,
            contactedTime: item?.contactedTime,
            type: item?.type
          },
          responsibleRedactio: null,

          responsiblePublication: null,
          programmationDate: null,
          publicationDate: null,
          contactDate: null,
          articleLink: null,
          spotLink: null,
          ancreLink: null,
          insertionLink: null,
          publicationStatus: null,
          redactionStatus: null,
          spotStatus: null,
        };
        return newSchemaItem;
      }

      return item;
    });

    setNetlinkingsTmp(newData);
  }

  function removeNetlinkingId(items) {
    const updatedNetlinkings = netlinkings.filter(
      (netlinking) =>
        !items.some((item) => item.netlinking.id === netlinking.netlinking.id)
    );
    setNetlinkingsTmp(updatedNetlinkings);
    setNetlinkings(updatedNetlinkings);
    RemoveNetlinkingToCampaigns(updatedNetlinkings);
  }



  const RemoveNetlinkingToCampaigns = (netlinkingswithoutSpot) => {
    axiosPixLink
      .post(
        `/campaign/AddSelectedNetlinking/${id}`,
        netlinkingswithoutSpot.map((object) => ({
          ...object,
          publicationStatus:
            object.publicationStatus === '' ? null : object.publicationStatus,
          redactionStatus:
            object.redactionStatus === '' ? null : object.redactionStatus,
        }))
      )
      .then((res) => {
        setIsSuccess(true);
        setNetlinkings(netlinkingswithoutSpot);
        setErrorSuccessMsg(
          `${t('Les spots')} ${t('ont été supprimés avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  const sendUpdateSaveSelectedNetlinking = (updatedData) => {
    setNetlinkingsTmp(updatedData);
    setNetlinkings(updatedData);
  };

  const updateSingleItemStatus = async (updatedData) => {
    try {
      const response = await axiosPixLink.post(
        `/campaign/UpdateSingleSelectedClientMultipleNetlinkings/${id}`,
        updatedData
      );
      // Handle success, reset form if needed
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };
  const SaveNetlinkingToCampaigns = () => {
    setNetlinkings(netlinkingsTmp);
    axiosPixLink
      .post(
        `/campaign/AddSelectedNetlinking/${id}`,
        netlinkingsTmp.map((object) => ({
          ...object,
          publicationStatus:
            object.publicationStatus === '' ? null : object.publicationStatus,
          redactionStatus:
            object.redactionStatus === '' ? null : object.redactionStatus,
        }))
      )
      .then((res) => {
        setIsSuccess(true);
        setNetlinkings(netlinkingsTmp);
        setErrorSuccessMsg(
          `${t('Les spots')} ${t('ont été ajoutés avec succes')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  function haveSharedDomain(array1, array2) {
    // Extract domains from array1
    const domains1 = array1.map((obj) => obj?.netlinking?.domain);

    // Iterate over array2 and check if any domain matches
    for (let i = 0; i < array2.length; i++) {
      if (domains1.includes(array2[i]?.domain)) {
        return true; // If match found, return true
      }
    }

    return false; // If no match found, return false
  }

  const GlobalSaveNetlinkingToCampaigns = () => {
    setConfirmationMsgSaveForce(
      "Êtes vous sûre de vouloir valider la sélection et informer l'équipe Pixalione"
    );
    setConfirmationSaveForce(true);
  };



  function hasStatusToBeValidatedClient(netlinkingArray) {

    return netlinkingArray.some(netlinking => {
      if (netlinking.spotTmpStatus) {
        return netlinking.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT;
      } else {
        return netlinking.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT;
      }
    })


  }



  function getStatusToBeValidatedClient(array) {
    return array.filter(netlinking => netlinking.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT || netlinking.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT);
  }
  const ValiderClientNetlinkingToCampaigns = () => {
    axiosPixLink
      .post(
        `/campaign/ValiderClientSelectedNetlinking/${id}`,
        netlinkings.map((object) => {
          const currentStatus =
            object.spotTmpStatus === undefined ||
              object.spotTmpStatus === null ||
              object.spotTmpStatus !== PIXLINK_STATUS.REFUSED_CLIENT
              ? PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT
              : PIXLINK_STATUS.REFUSED_CLIENT;

          return {
            ...object,
            publicationStatus:
              object.publicationStatus === '' ? null : object.publicationStatus,
            redactionStatus:
              object.redactionStatus === '' ? null : object.redactionStatus,
            spotStatus: currentStatus,
          };
        })
      )
      .then((res) => {
        setIsSuccess(true);
        updateCampaignStatus(
          PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED
        );
        setNetlinkings(
          netlinkings
            .filter(
              (item) => item.spotStatus !== PIXLINK_STATUS.REFUSED_PIXALIONE
            )
            .map((object) => {
              const currentStatus =
                object.spotTmpStatus === undefined ||
                  object.spotTmpStatus === null ||
                  object.spotTmpStatus !== PIXLINK_STATUS.REFUSED_CLIENT
                  ? PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT
                  : PIXLINK_STATUS.REFUSED_CLIENT;

              return {
                ...object,
                publicationStatus:
                  object.publicationStatus === ''
                    ? null
                    : object.publicationStatus,
                redactionStatus:
                  object.redactionStatus === '' ? null : object.redactionStatus,
                spotStatus: currentStatus,
                spotTmpStatus: null
              };
            })
        );
        setErrorSuccessMsg(
          `${t('Votre Consultant')} ${t('a été notifié avec succès')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  const sendNewWantedNetlinkingExport = (newWantedNetlinking) => {
    setNetlinkingToExportAddFavorite(newWantedNetlinking)
  };


  const addNetlinkingFavorite = () => {
    const newWantedNetlinkingId = netlinkingToExportAddFavorite.map((netlinking) => (netlinking.netlinking.id))

    axiosPixLink
      .post(
        `/campaign/addNetlinkingToWantedNetlinking/${id}`,
        newWantedNetlinkingId
      )
      .then((res) => {
        setIsSuccess(true);
        setWantedNetlinkings([...wantedNetlinkings, ...netlinkingToExportAddFavorite.map((netlinking) => (netlinking.netlinking))]);
        setErrorSuccessMsg(
          `${t('Spots Ajoutés au favoris')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };


  const removeNetlinkingFavorite = () => {
    const newWantedNetlinkingId = netlinkingToExportAddFavorite.map((netlinking) => (netlinking.netlinking.id))
    axiosPixLink
      .post(
        `/campaign/removeNetlinkingFromWantedNetlinking/${id}`,
        newWantedNetlinkingId
      )
      .then((res) => {
        setIsSuccess(true);
        const filteredItems = wantedNetlinkings.filter(netlinking => !newWantedNetlinkingId.includes(netlinking.id));

        setWantedNetlinkings(filteredItems);
        setErrorSuccessMsg(
          `${t('Spots sont retirés de favoris')} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };
  const handleFeedbackSubmit = (feedback) => {

    axiosPixLink
      .post(
        `/campaign/validerClientSelectedNetlinking/${id}`,
        feedback)
      .then((res) => {
        setIsSuccess(true);
        setIsSentFeedBackTmp(true)
        setErrorSuccessMsg(
          `${t('Votre Commentaire')} ${t('a été envoyé avec succès')} !`
        );
        let newNetlinkings = netlinkings
        feedback.forEach(obj1 => {
          newNetlinkings.forEach(obj2 => {
            if (obj1.netlinking.id === obj2.netlinking.id) {
              obj2.feedback = obj1.feedback; // Update feedback if netlinking.id matches
            }
          });
        });
        setNetlinkings(newNetlinkings)
        setErrorSuccess(true);
        setFeedbackModalOpen(false)
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 404:
            setErrorSuccessMsg(`${t("La campagne n'existe plus")}`);
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };
  function openPopupFeedBacks() {
    setFeedbackModalOpen(true)
  }
  function openPopupFeedBackShow() {
    setShowFeedbackModalOpen(true)
  }

  return (
    <div>
      <div className="content-center">
        <div className="row-homePage">
          <div className="col-12 col-md-3" style={{ marginLeft: '10px' }}>
            <div className="box-wrapper" style={{ height: '289px' }}>
              <div className="header-title mb-0">
                {campaignStatus ===
                  PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_EDITOR_TO_BE_COMMANDED
                  ? t('Nombre de liens demandés')
                  : t('Nombre de liens proposé')}
              </div>
              <div className="box-graph">
                <div className="d-flex justify-content-center">
                  {nbrOfLinksDemanded > 0 ? (
                    <MyCustomHighcharts
                      value={
                        netlinkings.filter(
                          (item) =>
                            item.spotStatus !== 'TO_DEFINE' &&
                            item.spotStatus !== 'TO_BE_VALIDATED_SUPER_ADMIN' &&
                            item.spotStatus !==
                            'TO_BE_VALIDATED_SUPER_CONSULTANT' &&
                            item.spotStatus !==
                            PIXLINK_STATUS.REFUSED_PIXALIONE &&
                            item.spotStatus !== null &&
                            item.spotStatus !== undefined
                        )?.length
                      }
                      maxValue={calculateMinimumLinks(nbrOfLinksDemanded)}
                    ></MyCustomHighcharts>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {netlinkings.filter(
            (item) =>
              item.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT ||
              item.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT ||
              item.spotStatus === PIXLINK_STATUS.TO_BE_COMMANDED ||
              item.spotStatus === PIXLINK_STATUS.REFUSED_CLIENT
          ).length > 0 && (
              <div className="col-12 col-md-3" style={{ marginLeft: '10px' }}>
                <div className="box-wrapper" style={{ height: '289px' }}>
                  <div className="header-title mb-0">
                    {t('Nombre de liens par Status')}
                  </div>
                  <div className="box-graph">
                    <div className="d-flex justify-content-center">
                      {nbrOfLinksDemanded > 0 ? (
                        <span>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                            }}
                          >
                            {calculateClientValidateClient(netlinkings) > 0 && (
                              <MyCustomHighchartsSmall
                                defaultColor={COLOR_JAUGE.ORANGE}
                                label={t('A  valider client')}
                                value={calculateClientValidateClient(netlinkings)}
                                maxValue={calculateMinimumLinks(
                                  nbrOfLinksDemanded
                                )}
                              ></MyCustomHighchartsSmall>
                            )}
                            {calculateClientRefusedClient(netlinkings) > 0 && (
                              <MyCustomHighchartsSmall
                                label={t('Refusé client')}
                                defaultColor={COLOR_JAUGE.RED}
                                value={calculateClientRefusedClient(netlinkings)}
                                maxValue={calculateMinimumLinks(
                                  nbrOfLinksDemanded
                                )}
                              ></MyCustomHighchartsSmall>
                            )}
                            {calculateClientToBeFilteredClient(netlinkings) >
                              0 && (
                                <MyCustomHighchartsSmall
                                  defaultColor={COLOR_JAUGE.BLUE}
                                  label={t('Validé client')}
                                  value={calculateClientToBeFilteredClient(
                                    netlinkings
                                  )}
                                  maxValue={calculateMinimumLinks(
                                    nbrOfLinksDemanded
                                  )}
                                ></MyCustomHighchartsSmall>
                              )}
                            {calculateClientToBeCommandedClient(netlinkings) >
                              0 && (
                                <MyCustomHighchartsSmall
                                  label={t('A commander')}
                                  defaultColor={COLOR_JAUGE.GREEN}
                                  value={calculateClientToBeCommandedClient(
                                    netlinkings
                                  )}
                                  maxValue={calculateMinimumLinks(
                                    nbrOfLinksDemanded
                                  )}
                                ></MyCustomHighchartsSmall>
                              )}
                          </div>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="col-12 col-md-3" style={{ marginLeft: '10px' }}>
            <div className="box-wrapper" style={{ height: '289px' }}>
              <div className="header-title mb-0">
                {t('Quota de refus disponible')}
              </div>
              <div className="box-graph">
                <div className="d-flex justify-content-center">
                  {nbrOfLinksDemanded > 0 ? (
                    <span>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MyCustomReverseHighChats
                          defaultColor="#FF8D4B"
                          value={calculateClientRefusedClient(netlinkings)}
                          maxValue={Math.floor(
                            calculateMinimumLinks(nbrOfLinksDemanded) -
                            calculateClientMinimumLinks(nbrOfLinksDemanded)
                          )}
                        ></MyCustomReverseHighChats>
                      </div>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 mb-2">
          <ul className="nav nav-tabs nav-tabs-ct">
            {navItems.map((item, index) => (
              <li key={index} className="nav-item">
                <button
                  onClick={() => {
                    setActive(item.active);
                  }}
                  className={`nav-link ${item.active === active ? 'active' : ''
                    }`}
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                >
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="row-homePage">
          {active === 1 && (
            <div
              className="col-12 col-md-12 mr-3"
              style={{ marginRight: '5px' }}
            >
              <div className="box-wrapper">
                <div className="box-list-header"></div>
                <ClientSelectedSpotTableControl
                  netlinkings={netlinkings.filter(
                    (item) =>
                      item.spotStatus !== 'TO_DEFINE' &&
                      item.spotStatus !== 'TO_BE_VALIDATED_SUPER_ADMIN' &&
                      item.spotStatus !== 'TO_BE_VALIDATED_SUPER_CONSULTANT' &&
                      item.spotStatus !== PIXLINK_STATUS.REFUSED_PIXALIONE &&
                      item.spotStatus !== null &&
                      item.spotStatus !== undefined
                  )}
                  campaignFilter={null}
                  wantedNetlinkings={wantedNetlinkings}
                  historicBackLinkings={historicBackLinkings}
                  removeNetlinkingId={removeNetlinkingId}
                  SaveNetlinkingToCampaigns={SaveNetlinkingToCampaigns}
                  sendUpdateSaveSelectedNetlinking={
                    sendUpdateSaveSelectedNetlinking
                  }
                  sendNewWantedNetlinkingExport={sendNewWantedNetlinkingExport}
                  nbrOfLinksDemanded={nbrOfLinksDemanded}
                  campaignStatus={campaignStatus}
                  updateSingleItemStatus={updateSingleItemStatus}
                  campaignType={campaignType}
                ></ClientSelectedSpotTableControl>

                <div style={{ marginBottom: '25px' }}></div>
              </div>
            </div>
          )}
          {active === 2 && (
            <div
              className="col-12 col-md-12 mr-3"
              style={{ marginRight: '5px' }}
            >
              <div className="box-wrapper">
                <div className="box-list-header"></div>
                <ClientHistoricalBacklinkingsTableControl
                  historicBackLinkings={historicBackLinkings}
                  campaignFilter={null}
                  wantedNetlinkings={wantedNetlinkings}
                  removeNetlinkingId={removeNetlinkingId}
                  SaveNetlinkingToCampaigns={SaveNetlinkingToCampaigns}
                ></ClientHistoricalBacklinkingsTableControl>
                <div style={{ marginBottom: '25px' }}></div>
              </div>
            </div>
          )}
        </div>
        {active === 1 && (
          <>
            {campaignType && (<span>
              {netlinkings.filter(
                (item) =>
                  item.spotStatus !== 'TO_DEFINE' &&
                  item.spotStatus !== 'TO_BE_VALIDATED_SUPER_ADMIN' &&
                  item.spotStatus !== 'TO_BE_VALIDATED_SUPER_CONSULTANT' &&
                  item.spotStatus !== null &&
                  item.spotStatus !== undefined
              ).length < calculateMinimumLinks(nbrOfLinksDemanded) || hasStatusToBeValidatedClient(netlinkings) ||
                (campaignStatus !==
                  PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED && campaignStatus !== PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED) ? (
                <button
                  className="bt-submit-disabled-y"
                  style={{
                    margin: '0 auto',
                    marginTop: '5px',
                    marginLeft: '5px',
                    alignSelf: 'right',
                    float: 'right',
                  }}
                >
                  {t('Notifier Pixalione')}
                </button>
              ) : (
                <button
                  className="bt-submit-y"
                  style={{
                    margin: '0 auto',
                    marginTop: '5px',
                    marginLeft: '5px',
                    alignSelf: 'right',
                    float: 'right',
                  }}
                  onClick={GlobalSaveNetlinkingToCampaigns}
                >
                  {t('Notifier Pixalione')}
                </button>
              )}
            </span>)}
            {campaignType && !isFeedBackSent && !isSentFeedBackTmp && (<span>
              {(campaignStatus == PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED || campaignStatus == PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED || campaignStatus == PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED) && netlinkingToExportAddFavorite.length > 0 ? (

                <button
                  className="bt-submit-y"
                  style={{
                    margin: '0 auto',
                    marginTop: '5px',
                    marginLeft: '5px',
                    alignSelf: 'right',
                    float: 'right',
                  }}
                  onClick={openPopupFeedBacks}
                >
                  {t('Envoyer commentaires')}
                </button>) : (<button
                  className="bt-submit-disabled-y"
                  style={{
                    margin: '0 auto',
                    marginTop: '5px',
                    marginLeft: '5px',
                    alignSelf: 'right',
                    float: 'right',
                  }}
                  disabled={true}
                  onClick={openPopupFeedBacks}
                >
                  {t('Envoyer commentaires')}
                </button>

              )}
            </span>)}

            {campaignType && (isFeedBackSent || isSentFeedBackTmp) && (<span>

              <button
                className="bt-submit-y"
                style={{
                  margin: '0 auto',
                  marginTop: '5px',
                  marginLeft: '5px',
                  alignSelf: 'right',
                  float: 'right',
                }}
                onClick={openPopupFeedBackShow}
              >
                {t('Mes Feedbacks')}
              </button>
            </span>)}

            {campaignType && (campaignStatus ==
              PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED || campaignStatus ==
              PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED || campaignStatus == PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED) && (<span>
                <span>
                  {netlinkingToExportAddFavorite.length > 0 ? (<button
                    className="bt-submit-y"
                    style={{
                      margin: '0 auto',
                      marginTop: '5px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      alignSelf: 'left',
                      float: 'left',
                    }}
                    onClick={addNetlinkingFavorite}
                  >
                    {t('ajouter des spots aux favoris')}
                  </button>) : (
                    <SmallCustomTooltip
                      title={
                        <span>
                          <p className="black-color">{t("Ajouter certains des spots sélectionnés aux favoris afin qu'ils soient une priorité pour notre équipe.")}</p>
                        </span>
                      }
                      placement="top"
                    ><span>
                        <button
                          className="bt-submit-disabled-y"
                          style={{
                            margin: '0 auto',
                            marginTop: '5px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            alignSelf: 'left',
                            float: 'left',
                          }}
                          disabled={true}
                          onClick={addNetlinkingFavorite}
                        >
                          {t('ajouter des spots aux favoris')}
                        </button>
                      </span>
                    </SmallCustomTooltip>)}
                </span>
              </span>)}
            {campaignType && (campaignStatus ==
              PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED || campaignStatus ==
              PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED || campaignStatus == PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED
            ) && (<span>
              <span>
                {netlinkingToExportAddFavorite.length > 0 ? (<button
                  className="bt-submit-y"
                  style={{
                    margin: '0 auto',
                    marginTop: '5px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    alignSelf: 'left',
                    float: 'left',
                  }}
                  onClick={removeNetlinkingFavorite}
                >
                  {t('supprimer des spots de favoris')}
                </button>) : (<button
                  className="bt-submit-disabled-y"
                  style={{
                    margin: '0 auto',
                    marginTop: '5px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    alignSelf: 'left',
                    float: 'left',
                  }}
                  disabled={true}
                  onClick={removeNetlinkingFavorite}
                >
                  {t('supprimer des spots de favoris')}
                </button>)}
              </span>
            </span>)}
          </>
        )}

      </div>

      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          setErrorSuccess(false);
          //       history.goBack();
        }}
        success={isSuccess}
      />
      <ConfirmationModal
        open={confirmationSaveForce}
        message={confirmationMsgSaveForce}
        handleClose={() => setConfirmationSaveForce(false)}
        executeAction={() => {
          ValiderClientNetlinkingToCampaigns();
          setConfirmationSaveForce(false);
        }}
      />
      <FeedbackModal
        array={netlinkingToExportAddFavorite}
        open={feedbackModalOpen}
        handleClose={() => setFeedbackModalOpen(false)}
        onSubmit={handleFeedbackSubmit}
      />
      <FeedbackModalShow
        array={netlinkings.filter(item => item.feedback != null && item.feedback != "")}
        open={showFeedbackModalOpen}
        handleClose={() => setShowFeedbackModalOpen(false)}
        onSubmit={handleFeedbackSubmit}
      />
    </div>
  );
}
