import ConsultantProfile from 'pages/Consultant/ConsultantProfile';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import { ConsultantHomePage } from 'pages/Consultant/ConsultantHomepage';
import ConsultantOrdersOverdue from 'pages/Consultant/ConsultantOrdersOverdue';
import ConsultantOrdersInProgress from 'pages/Consultant/ConsultantOrdersInProgress';
import ConsultantSearchNetlinking from 'pages/Consultant/ConsultantSearchNetlinking';
import ConsultantNetlinkingCreate from 'pages/Consultant/ConsultantCreateNetlinking';
import ConsultantNetlinkingDetail from 'pages/Consultant/ConsultantNetlinkingDetail';
import ConsultantFicheClient from 'pages/Consultant/ConsultantFicheClient';
import ConsultantCampaignDetail from 'pages/Consultant/ConsultantCampaignDetail';
import ConsultantOrdersByWebsite from 'pages/Consultant/ConsultantOrdersByWebsite';
import ConsultantNetlinkingDetailShortcut from 'pages/Consultant/ConsultantNetlinkingDetailShortcut';
import StillWorkingOnIt from './UnderConstruction';
import ConsultantOnlineOdrers from 'pages/Consultant/ConsultantOnlineOdrers';
export default class ConsultantMainMenu extends React.Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route exact path="/Consultant" component={ConsultantHomePage} />
          <Route path="/Consultant/MainMenu" component={ConsultantHomePage} />
          <Route path="/Consultant/MonProfil" component={ConsultantProfile} />
          <Route
            path="/Consultant/CampagnesEnCour"
            component={ConsultantOrdersInProgress}
          />
          <Route
            path="/Consultant/CampagnesEnRetard"
            component={ConsultantOrdersOverdue}
          />
          <Route
            path="/Consultant/ChercherSpot"
            component={ConsultantSearchNetlinking}
          />
          <Route
            path="/Consultant/CreerSpot"
            component={ConsultantNetlinkingCreate}
          />
          <Route
            path="/Consultant/SpotDetail"
            component={ConsultantNetlinkingDetail}
          />
          <Route path="/Consultant/Spot/Detail"
            component={ConsultantNetlinkingDetailShortcut} />
        </Switch>
        <Route
          path="/Consultant/FicheClient/:id"
          component={ConsultantFicheClient}
        ></Route>
        <Route
          path="/Consultant/DetailsCampagne/:id"
          component={ConsultantCampaignDetail}
        />
        <Route
          path="/Consultant/Website/:id/Campagnes"
          component={ConsultantOrdersByWebsite}
        ></Route>
        <Route
          path="/Consultant/CampagnesEnLigne"
          component={ConsultantOnlineOdrers}
        ></Route>
        <Route
          path="/Consultant/LiensSupprimé"
          component={StillWorkingOnIt}
        ></Route>
      </Layout>
    );
  }
}
