import React, { useEffect, useState } from 'react';
import axiosPrivate from 'config/axiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, UserSessionSelector } from 'store/UserSession';
import { useHistory } from 'react-router-dom';

import { disabledInput } from 'components/shared/disabledClasses';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { PWD_REGEX, PHONENUMBER_REGEX } from 'utils/Regex';
import { lower } from 'utils/StringUtils';

import '../shared/Profile.css';
import { useTranslation } from 'react-i18next';
import axiosPixLink from 'config/axiosPixLink';
import { AssignSuperAdminEdit } from 'components/shared/SuperAdminNotificationAssignement/AssignSuperAdmin';


const API = {
    getAllSuperAdmins: '/user/superadmins',
    getAllSuperAdminsNotificationsOn: '/user/superadmins/notification/on',
    updateNotifications: '/user/update-notifications'

};

export default function SuperAdminNotifications() {

    const history = useHistory();
    const { t } = useTranslation();

    const [superAdmin, setSuperAdmin] = useState([])


    const [superAdminsList, setSuperAdminsList] = useState([])

    const [assignedSuperAdmins, setAssignedSuperAdmins] = useState([
        {
            name: '',
            firstName: '',
            lastName: '',
            isNew: false,
        },
    ]);


    const [errorSuccess, setErrorSuccess] = useState(false);
    const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
        document.title = t('PixLink - Notifications');
        axiosPixLink.get(API.getAllSuperAdmins).then((res) => {
            setSuperAdminsList(res.data);
        });
        axiosPixLink.get(API.getAllSuperAdminsNotificationsOn).then((res) => {
            if (res.data.length > 0) {
                setAssignedSuperAdmins(res.data)
            }
        });
    }, [t]);

    const handleSubmit = () => {
        axiosPixLink.put(API.updateNotifications, assignedSuperAdmins.map(superAdmin => superAdmin.email), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setErrorSuccessMsg(t(`la liste de notification mise à jour avec succès`));
            setIsSuccess(true);
            setErrorSuccess(true);
        })
            .catch(() => {
                setErrorSuccessMsg(
                    t(
                        `Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.`
                    )
                );
                setIsSuccess(true);
                setErrorSuccess(true);
            });

    };
    return (
        <div className="content-center">
            <div className="box-header-top">
                <span onClick={history.goBack} className="bt-circle me-3" />
                <h1>{t('Notifications')}</h1>
            </div>
            <div className="row">
                <div className="box-over" style={{ position: 'relative' }}>
                    <div className="box-img-bg bg-4" />
                    <div className="box-img-bg bg-5" />
                    <div className="box-img-bg bg-6" />
                    <div className="col-12 col-md-10 box-screen">
                        <div className="box-wrapper">


                            <div className="row row-mb">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div
                                            className={
                                                'label-title'
                                            }
                                        >
                                        </div>
                                        <div>
                                            {assignedSuperAdmins.map((superAdmin, index) => (
                                                <AssignSuperAdminEdit
                                                    entityName={'SuperAdmin'}
                                                    superAdminsList={superAdminsList.filter(
                                                        (item) =>
                                                            !assignedSuperAdmins.some(
                                                                (item2) => item2.email === item.email
                                                            )
                                                    )}
                                                    superAdmin={superAdmin}
                                                    setValue={(option) => setSuperAdmin(option)}
                                                    id={index}
                                                    list={superAdminsList.filter(
                                                        (item) =>
                                                            !superAdminsList.some(
                                                                (item2) => item2.email === item.email
                                                            )
                                                    )}
                                                    new={true}
                                                    data={superAdmin}
                                                    key={index}
                                                    addSuperAdmin={() =>
                                                        setAssignedSuperAdmins([
                                                            ...assignedSuperAdmins,
                                                            {
                                                                email: '',
                                                                firstName: '',
                                                                lastName: '',
                                                            },
                                                        ])
                                                    }
                                                    updateSuperAdminList={(type, object) => {
                                                        const newValue = superAdmin;
                                                        newValue[type] = object;
                                                        const newList = assignedSuperAdmins;
                                                        newList[index] = newValue;
                                                        setAssignedSuperAdmins([...newList]);
                                                    }}
                                                    isDeleteable={assignedSuperAdmins.length > 1}
                                                    deleteSuperAdmin={(index) => {
                                                        if (assignedSuperAdmins.length > 1) {
                                                            let newList = assignedSuperAdmins;
                                                            newList.splice(index, 1);
                                                            setAssignedSuperAdmins([...newList]);
                                                        }
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {!true ? (
                                <button className="bt-submit-disabled-y float-end">
                                    {' ' + t('Sauvegarder') + ' '}
                                </button>
                            ) : (
                                <button
                                    className="bt-submit-y float-end"
                                    onClick={handleSubmit}
                                >
                                    {' ' + t('Sauvegarder') + ' '}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ErrorSuccesModal
                open={errorSuccess}
                message={errorSuccessMsg}
                handleClose={() => setErrorSuccess(false)}
                success={isSuccess}
            />
        </div>
    );
}
