export const italianTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Le linee di comando sono state aggiornate con successo!',
  'Le texte a été enregistré avec succès':
    'Il testo è stato registrato con successo',
  'Nombre de mots': 'Numero di parole',
  Lingala: 'Lingala',
  'Nom/Prénom du client': 'Nome e cognome del cliente',
  Bashkir: 'Bashkir',
  "La date doit être ultérieure à aujourd'hui.":
    'La data deve essere successiva a quella di oggi.',
  'Contenu rédigé': 'Contenuto scritto',
  'Nom/prénom': 'Cognome/nome',
  'Mot de passe incorrect': 'Password errata',
  Institutions: 'Istituzioni',
  'Veuillez donner un descriptif au document téléchargé':
    'Si prega di fornire una descrizione del documento scaricato',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Avanti',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"':
    'Separare le parole chiave con un "enter".',
  Allemand: 'Tedesco',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    "è stata inviata al vostro indirizzo e-mail un'e-mail contenente un link per la reimpostazione della password",
  'Chef edito': 'Capo redattore',
  Croate: 'Croato',
  'Nb de textes': 'Numero di testi',
  Violet: 'Viola',
  'Ce champs doit être sup ou égale nombre de texte':
    'Questo campo deve essere maggiore o uguale al numero di testo',
  'a été activé avec succès': 'è stato attivato con successo',
  Népalais: 'Nepalese',
  'Nombre de FAQ': 'Numero di FAQ',
  Urdu: 'Urdu',
  'Analyses restantes': 'Analisi rimanenti',
  Indonésien: 'Indonesiano',
  Breton: 'Bretone',
  Livrés: 'Consegnato',
  Associations: 'Associazioni',
  'Le client': 'Il cliente',
  'Révision demandée': 'Richiesta di revisione',
  "Une erreur s'est produite, veuillez réessayer":
    'Si è verificato un errore, riprovare',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Aggiornamento non autorizzato in quanto i testi sono stati consegnati',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Per aggiungere testo (ottimizzare i contenuti esistenti)',
  Gaélique: 'Gaelico',
  'La ligne de commande pour': 'La riga di comando per',
  'Business/Management': 'Affari/Gestione',
  Serbe: 'Serbo',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Vi preghiamo di indicare qui di seguito le vostre richieste di modifica, tenendo conto delle nostre condizioni di stiratura',
  'rédacteurs trouvés': 'scrittori trovati',
  'Kirghiz ; Kirghiz': 'Kirghiz; Kirghiz',
  Différence: 'Differenza',
  'en cours': 'in corso',
  'Sami du Nord': 'Sami del Nord',
  Comparer: 'Confronto',
  Lituanien: 'Lituano',
  'PixLink - Mon tableau de bord': 'PixLink - Il mio cruscotto',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Deve essere lungo almeno 8 caratteri con una lettera maiuscola, un numero, un carattere speciale e non identico al nome.',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Inserire campi validi',
  Basque: 'Basco',
  'Exporter .csv': 'Esportazione .csv',
  'Confirmer le nouveau mot de passe': 'Confermare la nuova password',
  "Pas d'options": 'Nessuna opzione',
  'Banque/Assurance': 'Banche/Assicurazioni',
  'Vos demandes': 'Le vostre richieste',
  'Coût d\u2019achat': 'Costo di acquisto',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès':
    'Il testo è stato cancellato con successo',
  'Mes Commandes': 'I miei ordini',
  Zoulou: 'Zulu',
  Afficher: 'Vista',
  Texte: 'Testo',
  Islandais: 'Islandese',
  Russe: 'Russo',
  'Tous les textes': 'Tutti i testi',
  Czech: 'Ceca',
  'Comparaison hors ligne': 'Confronto offline',
  Téléphonie: 'Telefonia',
  'Valider le texte': 'Convalidare il testo',
  'Votre text ne de doit pas dépasser 500 mots':
    'Il testo non deve superare le 500 parole',
  'Coquilles (double espace, « s »\u2026)':
    'Conchiglie (doppio spazio, "s"...)',
  'le consultant': 'il consulente',
  Coréen: 'Coreano',
  'Nb de Silo': 'Numero di silos',
  Validés: 'Convalidato',
  'non attribuées': 'non allocato',
  Néerlandais: 'Olandese',
  'Voulez-vous vraiment supprimer': 'Volete davvero cancellare',
  Rundi: 'Rundi',
  Regional: 'Regionale',
  'Compte client': 'Conto cliente',
  Global: 'Globale',
  'Tous les rôles': 'Tutti i ruoli',
  Musique: 'Musica',
  Perso: 'Personale',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Sembra che non abbiate più credito di analisi',
  Bambara: 'Bambara',
  Sortir: 'Esci',
  optionel: 'opzionale',
  'Temps restant': 'Tempo rimanente',
  Ukrainien: 'Ucraino',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'La richiesta di revisione è stata inviata.',
  'PixLink - Créer rédacteur': 'PixLink - Creare un scrittore',
  "n'existe pas": 'non esiste',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'non può essere cancellato perché è collegato a siti web',
  'Campagnes en cours': 'Ordini in corso',
  'Textes livrés': 'Testi consegnati',
  Validation: 'Convalida',
  'Non attribuées': 'Non assegnato',
  'Voulez-vous vraiment désactiver': 'Si vuole davvero disattivare',
  'Catalan ; Valencien': 'Catalano; Valenciano',
  'Nombre de textes restants': 'Numero di testi rimanenti',
  'Ne doit pas contenir votre nom': 'Non deve contenere il vostro nome',
  Documentation: 'Documentazione',
  Kazakh: 'Kazako',
  'Numéro invalide': 'Numero non valido',
  'Veuillez saisir un texte ou importer un fichier.':
    'Inserire un testo o importare un file.',
  'Nb de FAQ': 'Numero di FAQ',
  Analyser: 'Analizzare',
  Turc: 'Turco',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Qualsiasi richiesta non specificata nel documento',
  Wallon: 'Vallonia',
  Birmane: 'Birmano',
  Irlandais: 'Irlandese',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Reimpostare la password',
  Cuisine: 'Cucina',
  'Haïtien ; créole haïtien': 'Haitiano; creolo haitiano',
  'Vous ne pouvez pas supprimer le rédacteur':
    "Non è possibile eliminare l'scrittore",
  Validé: 'Convalidato',
  "cet email n'existe pas": 'questa email non esiste',
  Historique: 'La storia',
  Confirmer: 'Confermare',
  'à cocher si le nombre de mots est différent par texte':
    'da spuntare se il numero di parole è diverso per ogni testo',
  'Fiche du site web ': 'Scheda del sito web',
  Désactiver: 'Disattivare',
  'Travaux/BTP': 'Lavori/Costruzioni',
  'La fiche du website': 'La scheda del sito web',
  'Si le contenu ne respecte pas les règles du brief':
    'Se il contenuto non rispetta le regole del brief',
  Norvégien: 'Norvegese',
  '0 rédacteurs': '0 scrittori',
  indisponible: 'non disponibile',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Testi rivisti',
  'Status texte': 'Stato del testo',
  'textes livrés en attente de validation':
    'testi consegnati in attesa di convalida',
  'PixLink - Mon Profile': 'PixLink - Il mio profilo',
  'le client': 'il cliente',
  Romanche: 'Romanche',
  'URL du site': 'URL del sito',
  'nouvelles notifications': 'nuove notifiche',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Maldiviano',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Siete sicuri di voler salvare il testo',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Si prega di aggiungere un testo iniziale per salvare il testo come bozza',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Il contratto per il cliente',
  rédacteurs: 'scrittori',
  'Ce champ est obligatoire': 'Questo campo è obbligatorio',
  'Tarif/Mot': 'Tasso/parola',
  Tadjik: 'Tajik',
  'Réf devis': 'Citazione di riferimento',
  'Rechercher un rédacteur': 'Ricerca di uno scrittore',
  Albanais: 'Albanese',
  Galicien: 'Galiziano',
  Disponibilité: 'Disponibilità',
  'Les deux mots de passe ne sonts pas identiques':
    'Le due password non sono identiche',
  Tibétain: 'Tibetano',
  'PixLink - Gestion des utilisateurs': 'PixLink - Gestione degli utenti',
  'Ossétien ; Ossétique': 'Osseto; Ossetico',
  Tswana: 'Tswana',
  'Ajouter un client': 'Aggiungere un cliente',
  'Séparez les mots-clés par un " Enter ".':
    'Separare le parole chiave con un "Invio".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Temi',
  'Nb mots/texte': 'Numero di parole/testo',
  Commerce: 'Commercio',
  'Voir les commandes': 'Vedere gli ordini',
  'Nombre de mots par texte': 'Numero di parole per testo',
  'Lien drive...': 'Unità di collegamento...',
  'Hollandais ; Flamand': 'Olandese; fiammingo',
  'Mon profil': 'Il mio profilo',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Questo campo deve essere maggiore di 0 perché la fatturazione non è reale.',
  'Le texte a été validé avec succès':
    'Il testo è stato convalidato con successo',
  Jeux: 'Giochi',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Si vuole davvero rimuovere la linea di comando per',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Nord; Ndebele Nord',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Slavo ecclesiastico; Slavo antico; Slavo ecclesiastico; Bulgaro antico; Slavo ecclesiastico antico',
  Swahili: 'Swahili',
  Voyage: 'Viaggi',
  'Nombre de mot à ajouter': 'Numero di parole da aggiungere',
  'Liste des rédacteurs': 'Elenco dei scrittori',
  'le rédacteur': "l'scrittore",
  Kanuri: 'Kanuri',
  rédacteur: 'scrittore',
  Tatar: 'Tatar',
  'Roumain ; Moldave ; Moldovan': 'Rumeno; moldavo',
  'Le texte initial de': 'Il testo originale della',
  'Mail clients': 'Posta del cliente',
  'Le contrat de': 'Il contratto di',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'almeno una lettera minuscola e una maiuscola, un carattere speciale e un numero',
  Transport: 'Trasporto',
  'Nombre total de mots (existants et ajoutés) :':
    'Numero totale di parole (esistenti e aggiunte) :',
  'Rechercher par mail, prénom, nom, site...':
    'Ricerca per e-mail, nome, cognome, sito...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Numero di parole da aggiungere (Min/Max) :',
  Compte: 'Conto',
  'Tourisme/Loisirs': 'Turismo/tempo libero',
  'Nouveau texte': 'Nuovo testo',
  Max: 'Massimo',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; groenlandese',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Ci sono uno o più errori nella finestra del gap semantico. Si prega di controllare il contenuto per correggerlo/li e di inviare nuovamente il testo.',
  'ne peut pas être supprimé pour le moment':
    'non può essere cancellato in questo momento',
  'Le texte': 'Il testo',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    "Esiste già un utente con l'e-mail",
  Cree: 'Cree',
  Prénom: 'Nome',
  'commandes prête': 'ordini pronti',
  Réinitialiser: 'Reset',
  'Veuillez fournir une date valide': 'Indicare una data valida',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Lussemburghese; Letzeburgesch',
  Suédois: 'Svedese',
  'Confirmer le mot de passe': 'Confermare la password',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Questo campo è obbligatorio e può contenere solo numeri',
  Sciences: 'Scienza',
  Tchétchène: 'Ceceno',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    "è l'unico client per un sito, è necessario aggiungerne altri per rimuoverlo.",
  'Le mot de passe de confirmation ne correspond pas':
    'La password di conferma non corrisponde',
  Swati: 'Swati',
  Danois: 'Danese',
  Sauvegarder: 'Risparmiare',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Modificare le seguenti informazioni: titolo e/o parola chiave',
  'importer un document': 'importare un documento',
  Maltais: 'Maltese',
  'Nb de mots': 'Numero di parole',
  'Les données fournies sont invalides': 'I dati forniti non sono validi',
  'Livraison estimée': 'Consegna stimata',
  'Grec moderne (1453-)': 'Greco moderno (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Numero totale di parole aggiunte',
  'le contenu présente des inexactitudes': 'il contenuto contiene inesattezze',
  Finnois: 'Finlandese',
  'Ajouter un admin': 'Aggiungere un amministratore',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'Modificare il consulente',
  'Liste des clients': 'Elenco dei clienti',
  'Nom client': 'Nome del cliente',
  Vert: 'Verde',
  'Mots clé manquants': 'Parole chiave mancanti',
  Lao: 'Lao',
  Somali: 'Somalo',
  'Nb de silo par texte': 'Numero di silos per testo',
  Italien: 'Italiano',
  'La commande a été mis à jour avec succès !':
    "L'ordine è stato aggiornato con successo!",
  Bosniaque: 'Bosniaco',
  'Thématiques d\u2019expertise': 'Temi di competenza',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Questa è la password che avete dimenticato',
  'a été désactivé avec succès': 'è stato disattivato con successo',
  Kongo: 'Kongo',
  'Postuler à la commande': "Richiedere l'ordine",
  'mot-clé': 'parola chiave',
  Abkhazien: 'Abcaso',
  Mongolien: 'Mongolo',
  'PixLink - Fiche site web': 'PixLink - Sito web',
  'Mail envoyé': 'Posta inviata',
  Géorgien: 'Georgiano',
  'Si des phrases ne sont pas dans un français correct':
    'Se le frasi non sono in francese corretto',
  'En cours': 'In corso',
  voir: 'vedere',
  "Date d'ajout": 'Data aggiunta',
  'Tarif au mot': 'Tariffa per parola',
  Thai: 'Thailandese',
  'Détails du texte': 'Dettagli del testo',
  'Commandes en retard': 'Ordini in ritardo',
  disponible: 'disponibile',
  Droit: 'Legge',
  Azerbaïdjanais: 'Azerbaigian',
  'Ajouter un consultant': 'Aggiungere un consulente',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Quando si riproducono i contenuti, saranno supportati solo i seguenti elementi',
  'doit comprendre au moins 8 charactères': 'deve contenere almeno 8 caratteri',
  'Titre de la campagne': "Titolo dell'ordine",
  'Langues bihari': 'Lingue bihari',
  'Livraison estimé au': 'Consegna prevista entro',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    "Il numero di testi nell'ordine non può essere inferiore al numero di testi assegnati agli autori. Modificare prima le informazioni nella riga di comando",
  'Date de livraison': 'Data di consegna',
  'Voulez-vous vraiment supprimer le contrat de': 'Volete davvero abolire il',
  'Facturation au réel': 'Fatturazione effettiva',
  'Gap sémantique': 'Lacuna semantica',
  Estonien: 'Estone',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Costo massimo di acquisto/parola',
  'Détail de la commande': "Dettagli dell'ordine",
  'Export en cours de téléchargement\u2026':
    'Esportazione in corso di caricamento...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    "Questo campo è obbligatorio e deve essere inferiore o uguale al numero totale di testi dell'ordine.",
  Arabe: 'Arabo',
  Bislama: 'Bislama',
  'Mail client': 'Posta del cliente',
  '(Titre, mots clé, url, balise, méta-description)':
    '(Titolo, parole chiave, url, tag, meta-descrizione)',
  'PixLink - Nouvelle commande': 'PixLink - Nuovo ordine',
  'Révision en attente': 'Recensione in corso',
  'Le consultant': 'Il consulente',
  Entreprise: 'Azienda',
  Aragonais: 'Aragonese',
  Kashmiri: 'Kashmiri',
  'a été activé': 'è stato attivato',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Associazione internazionale delle lingue ausiliarie)',
  Actualités: 'Notizie',
  'Votre décision a été prise en compte avec succès':
    'La vostra decisione è stata presa in considerazione con successo',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'contenuti in attesa di verifica da parte del cliente',
  'Fiche du rédacteur': "File dell'scrittore",
  Outillage: 'Strumenti',
  'PixLink - Détails de la commande': "PixLink - Dettagli dell'ordine",
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Aggiungete o completate il vostro contenuto qui sotto:',
  Ewe: 'Pecora',
  'contenu en attente de repasse rédacteur':
    "contenuto in attesa dell'scrittore di replay",
  'La commande a été supprimé avec succès':
    "L'ordine è stato cancellato con successo",
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Espressioni o frasi francesi che non vi si addicono',
  Corse: 'Corsica',
  Modifier: 'Modifica',
  'Nb de GMB': 'Numero di GMB',
  Oromo: 'Oromo',
  Polish: 'Polacco',
  'Insérez votre gap sémantique avec l\u2019occurence':
    "Inserite la vostra lacuna semantica con l'occorrenza",
  'Nb de textes à optimiser': 'Numero di testi da ottimizzare',
  Mode: 'Modalità',
  Akan: 'Akan',
  'Révisions effectués': 'Revisioni effettuate',
  'Modifier l\u2019admin': "Modificare l'amministratore",
  'Télécharger le document': 'Scarica il documento',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Note della scrittori',
  Quechua: 'Quechua',
  Féroïen: 'Faroese',
  'Modifier le rédacteur': "Cambiare l'scrittore",
  Mail: 'Posta',
  Rédacteurs: 'Scrittori',
  'PixLink - Détails du texte': 'PixLink - Dettagli sul testo',
  Hongrois: 'Ungherese',
  'Bon pour livraison': 'Buono per la consegna',
  'Au réel': 'Nel mondo reale',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Ebraico',
  'Nombre total de mots ajoutés :': 'Numero totale di parole aggiunte :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Deve essere inferiore al numero massimo',
  'Nom du texte': 'Nome del testo',
  'Ajouter un rédacteur': 'Aggiungere un scrittore',
  Ou: 'Oppure',
  Utilisateurs: 'Utenti',
  Heures: 'Orario',
  'Santé/Bien-être': 'Salute/Benessere',
  'Interlingue ; Occidental': 'Interlingua; occidentale',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Lettone',
  Anglais: 'Inglese',
  Envoyer: 'Inviare',
  'Tableau de bord': 'Cruscotto',
  'Demande de révision': 'Richiesta di revisione',
  'Résultat sémantique': 'Risultato semantico',
  'Chercher un rédacteur': 'Ricerca di un scrittore',
  'Ce champs est obligatoire': 'Questo campo è obbligatorio',
  Rédaction: 'scrittoreiale',
  'Modifier le client': 'Modificare il client',
  'Voulez-vous vraiment supprimer le rédacteur':
    "Si vuole davvero eliminare l'scrittore",
  Titre: 'Titolo',
  Langue: 'Lingua',
  'Mots clé': 'Parole chiave',
  'Les demandes suivantes ne seront pas prises en compte':
    'Non saranno prese in considerazione le seguenti domande',
  'délai dépassé': 'scadenza non rispettata',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Contratto esistente, modificare le informazioni',
  Igbo: 'Igbo',
  Yiddish: 'Yiddish',
  'Limburgan ; Limburger ; Limburgish': 'Limburghese; Limburger; Limburghese',
  'Commandes validées': 'Ordini convalidati',
  'listeRedacteur-': 'elencoRedattore-',
  Étape: 'Passo',
  'Energie/Environnement': 'Energia/Ambiente',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom':
    'non deve includere il proprio cognome/nome',
  'PixLink - Fiche rédacteur': "PixLink - Scheda dell'scrittore",
  'révisions effectuées en attentes de validation':
    'revisioni effettuate in attesa di convalida',
  'Textes validés': 'Testi convalidati',
  Art: 'Arte',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Versatile',
  Sundanese: 'Sundanese',
  'Nb total de textes': 'Numero totale di testi',
  'Nombre de mots gérés': 'Numero di parole gestite',
  "L'émail": 'Smalto',
  'Afficher les résultats': 'Mostra i risultati',
  'Frison occidental': 'Frisone occidentale',
  'Modification effectuée avec succès': 'Modifica completata con successo',
  'Balise titre': 'Tag del titolo',
  'PixLink - Mon profil': 'PixLink - Il mio profilo',
  'email invalide': 'e-mail non valida',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Si prega di aggiungere un url per poter salvare il testo come bozza',
  Macédonien: 'Macedone',
  'Votre candidature a bien été envoyée': 'La vostra domanda è stata inviata',
  'E-mail': 'E-mail',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Aggiungete o completate il vostro contenuto qui sotto',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Aggiunta di parole chiave con frequenza residua',
  'Fiche du site web': 'Scheda del sito web',
  Contacter: 'Contatto',
  Fidjien: 'Figiana',
  Déconnexion: 'Disconnessione',
  email: 'e-mail',
  'Nombre de textes': 'Numero di testi',
  'Enregistrer et informer client': 'Registrazione e informazione dei clienti',
  'Date de validation': 'Data di convalida',
  'Tonga (Îles Tonga)': 'Tonga (Isole Tonga)',
  Javanais: 'Giavanese',
  Portugais: 'Portoghese',
  Biélorusse: 'Bielorusso',
  'Pour ajouter un nouveau texte': 'Per aggiungere un nuovo testo',
  'Enfant/Puéri': 'Bambino/Puperium',
  'Mots clés': 'Parole chiave',
  Emploi: 'Occupazione',
  'Voulez-vous vraiment supprimer la commande de':
    'Volete davvero rimuovere il',
  'Mot clé': 'Parola chiave',
  Consultants: 'Consulenti',
  Volapük: 'Volapük',
  Services: 'Servizi',
  'mot-clé (fréquence)': 'parola chiave (frequenza)',
  'Ne plus afficher ce message': 'Non visualizzare più questo messaggio',
  Consultant: 'Consulente',
  "Une erreur s'est produite": 'Si è verificato un errore',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Almeno 8 caratteri con una lettera maiuscola, un numero, un carattere speciale',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Francese',
  validées: 'convalidato',
  'Url du site': 'Url del sito',
  Précédent: 'Precedente',
  'car celui-ci a déjà rédigé des textes':
    "perché quest'ultimo ha già redatto dei testi",
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Bozze',
  Ido: 'Ido',
  'Contenu disponible': 'Contenuto disponibile',
  Enseignement: 'Insegnamento',
  'a été livré avec succès': 'è stato consegnato con successo',
  Chinois: 'Cinese',
  Vietnamien: 'Vietnamita',
  'Nouveau client': 'Nuovo cliente',
  'Votre profil a été mis à jour avec succès.':
    'Il vostro profilo è stato aggiornato con successo.',
  Consignes: 'Istruzioni',
  'Mot de passe': 'Password',
  Annuler: 'Annullamento',
  Non: 'No',
  Nom: 'Nome',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'ci sono ordini relativi al sito web',
  Score: 'Punteggio',
  'Mot de passe mis à jour avec succès':
    'La password è stata aggiornata con successo',
  Latin: 'Latino',
  Technologie: 'Tecnologia',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'è stato creato con successo',
  Roumain: 'Rumeno',
  Assamais: 'Assamese',
  'Khmer central': 'Khmer centrale',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Analisi/Limite',
  Grec: 'Greco',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'I miei progetti',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'parola chiave1 (2)\nparola chiave2 (1)\nparola chiave3 (1)\nparola chiave4 (2)\n...',
  'ajouter un texte': 'aggiungere un testo',
  'Détail de la prestation': 'Dettagli del servizio',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    "È possibile esportare il report di uno o più ordini. Per esportare tutti gli ordini di un cliente, è sufficiente fare clic sull'icona gialla di download.",
  'Gestion des utilisateurs': 'Gestione degli utenti',
  'livré et inférieur au nombre de texte non affecté':
    'consegnati e inferiore al numero di testi non assegnati',
  'Date deadline validation client': 'Termine di convalida del cliente',
  Aymara: 'Aymara',
  Validées: 'Convalidato',
  Notes: 'Note',
  'Nombre de GMB': 'Numero di Ogm',
  'résultats sur': 'risultati su',
  'Prénom / Nom du client': 'Nome / Nome del cliente',
  'Tout télécharger': 'Scaricare tutti',
  'Champs obligatoires': 'Campi obbligatori',
  Éditer: 'Modifica',
  'Coller le texte initial dans le champs ci-dessous':
    'Incollare il testo originale nel campo sottostante',
  'ID rédacteur': 'ID scrittore',
  Arménien: 'Armeno',
  'Grande distribution': 'Distribuzione su larga scala',
  Welsh: 'Gallese',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Arricchimento semantico',
  'Détail de la prestation': 'Dettagli sulla consegna dei contenuti',
  Noir: 'Nero',
  'Ajouter un texte': 'Aggiungere un testo',
  'Immo/Defisc': 'Proprietà/Tasse',
  'Brief détaillé': 'Breve descrizione dettagliata',
  'Séparez les mots-clés par un Enter .':
    'Separate le parole chiave con un Invio .',
  Prix: 'Premi',
  'mot de passe oublié': 'Password dimenticata',
  'Ndebele, South ; South Ndebele': 'Ndebele, Sud; Ndebele meridionale',
  'Demander une révision': 'Richiedi una recensione',
  'Sotho, Southern': 'Sotho, Sud',
  'Le Titre de la campagne existe déjà': "Il titolo dell'ordine esiste già",
  'Félicitations !': 'Congratulazioni!',
  Kurde: 'Curdo',
  Adresse: 'Indirizzo',
  Espagnol: 'Spagnolo',
  Ndonga: 'Ndonga',
  'En retard': 'In ritardo',
  'a été ajouté avec succès': 'è stato aggiunto con successo',
  Commandes: 'Ordini',
  'a été mis en brouillon': 'è stato redatto',
  'Rédacteur attribué': 'scrittore assegnato',
  Rechercher: 'Ricerca',
  'Nouvelle commande': 'Nuovo ordine',
  'Commandes non attribuées': 'Ordini non assegnati',
  'Le texte a été mis en brouillon': 'Il testo è stato redatto',
  'Contact edito': 'Contatto edito',
  Langues: 'Le lingue',
  Orange: 'Arancione',
  'Nynorsk norvégien': 'Norvegese nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turcomanni',
  'contenu repassé, en attente validation client':
    'contenuto stirato, in attesa della convalida del cliente',
  'Nombre de mots rédigés': 'Numero di parole scritte',
  Malay: 'Malese',
  'Nombre de Silo': 'Numero di silo',
  Oui: 'Sì',
  Automobile: 'Automotive',
  Résultats: 'Risultati',
  Afar: 'Afar',
  'Tâche - id tâche': "Attività - id dell'attività",
  'Ce champ est obligatoire doit être supérieur à 0':
    'Questo campo è obbligatorio e deve essere maggiore di 0.',
  'Vous êtes sûr': 'È sicuro che',
  'Réinitialiser votre mot de passe': 'Reimpostare la password',
  Sindhi: 'Sindhi',
  Bengali: 'Bengalese',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, norvegese; Bokmål norvegese',
  'Nombre de Jours': 'Numero di giorni',
  'Min/Max mots': 'Parole min/max',
  Slovène: 'Sloveno',
  Site: 'Sito web',
  'Sinhala ; Cingalais': 'Sinhala; singalese',
  Bulgare: 'Bulgaro',
  'Liste des commandes': 'Elenco degli ordini',
  "L'utilisateur": "L'utente",
  Uzbek: 'Uzbeko',
  'Titre du texte': 'Titolo del testo',
  Postuler: 'Candidarsi a',
  'Nb de textes par Silo': 'Numero di testi per silo',
  'Nombre total de mots (existants et ajoutés)':
    'Numero totale di parole (esistenti e aggiunte)',
  'Max.': 'Max.',
  Beauté: 'Bellezza',
  Humanitaire: 'Umanitario',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Ricerca per autore o testo',
  'Balise meta-description': 'Tag Meta description',
  Malgache: 'Malgascio',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Questo campo deve essere maggiore di 0 e minore di 1',
  'Le rédacteur': "L'scrittore",
  'a été mis à jour avec succès': 'è stato aggiornato con successo',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Deve essere lungo almeno 8 caratteri con una lettera maiuscola, un numero, un simbolo e non identico al nome.',
  'textes en plus': 'altri testi',
  'Format souhaité': 'Formato desiderato',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': "Dettaglio linea d'ordine",
  'Veuillez préciser la date': 'Specificare la data',
  Pali: 'Pali',
  Clients: 'Clienti',
  'Total facturé': 'Totale fatturato',
  'contenu validé par le client': 'contenuto convalidato dal cliente',
  'Occitan (post 1500)': 'Occitano (dopo il 1500)',
  Japonais: 'Giapponese',
  Avaric: 'Avarico',
  'Voulez-vous vraiment activer': 'Volete davvero attivare',
  Sanskrit: 'Sanscrito',
  'Toutes les notifications': 'Tutte le notifiche',
  Marathi: 'Marathi',
  Persan: 'Persiano',
  'La commande pour le client': "L'ordine per il cliente",
  'Intitulé de la commande': "Titolo dell'ordine",
  Samoan: 'Samoano',
  Autres: 'Altro',
  Sport: 'Lo sport',
  "il y'a des commandes liés au site web ":
    'ci sono ordini relativi al sito web',
  'Ajout de mots-clé avec la fréquence restante':
    'Aggiunta di parole chiave con frequenza residua',
  'a été supprimé avec succès': 'è stato rimosso con successo',
  Postulé: 'Inviato',
  Literie: 'Biancheria da letto',
  'Édité par le client': 'Modificato dal cliente',
  Cornouailles: 'Cornovaglia',
  Tigrinya: 'Tigrino',
  Textes: 'Testi',
  'Soumettre un nouveau texte': 'Invia un nuovo testo',
  Téléphone: 'Telefono',
  Deadline: 'Scadenza',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    "Si è verificato un errore. Controllare l'inserimento o riprovare più tardi.",
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'La richiesta di aggiunta del credito viene inviata',
  'Le rédacteur ': "L'scrittore",
  'Demander des crédits': 'Richiesta di credito',
  "Cette commande n'existe pas": 'Questo comando non esiste',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitiano',
  Admins: 'Amministratori',
  'Mettez des mots-clés avec fréquence':
    'Impostare le parole chiave con frequenza',
  Notifications: 'Notifiche',
  'en retard': 'in ritardo',
  "l'admin": "l'amministratore",
  'Mail rédacteurs': 'scrittori della posta',
  Amharique: 'Amarico',
  Societe: 'Azienda',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Numero di testi aggiunti',
  Société: 'Azienda',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès':
    'Il testo è stato consegnato con successo',
  'Mettez votre texte': 'Inserite il vostro testo',
  "Oups....! Une erreur s'est produite": 'Oops....! Si è verificato un errore',
  'Nom/Prénom': 'Nome/Cognome',
  'Max doit être sup à Min': 'Max deve essere maggiore di Min',
  'Importer un autre document': 'Importare un altro documento',
  'Votre demande a expirée .. veuillez refaire une autre':
    "La sua domanda è scaduta... la preghiamo di farne un'altra.",
  'Veuillez corriger le format votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Correggere il formato della lacuna semantica per salvare il testo come bozza.',
  Exemple: 'Esempio',
  Slovaque: 'Slovacco',
  Finance: 'Finanza',
  Réactiver: 'Riattivare',
  'Nb textes': 'Numero di testi',
  'Intitulé commande': "Titolo dell'ordine",
  Supprimer: 'Cancellare',
  Brouillon: 'Bozza',
  'Mots clés restants': 'Parole chiave rimanenti',
  Date: 'Data',
  Rôle: 'Ruolo',
  'Ce champs doit être sup à 0': 'Questo campo deve essere maggiore di 0',
  'Maison/Déco': 'Casa/Decorazione',
  'Vous avez': 'Avete',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Questa riga di comando non può essere cancellata perché una parte del testo è stata consegnata',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'controlla la tua e-mail e clicca sul link per continuare!',
  Sarde: 'Sardegna',
  'Note rédacteur': "Nota dell'scrittore",
  'Uighur ; Uyghur': 'Uighur; Uyghur',
  'Name ': 'Nome',
  'Commandes intelligentes': 'Ordini smart',
  'Commandes avec révision': 'Ordini smart + revision',
  'Type de contenu': 'Tipo di contenuto',
  'Fiche produit': 'Scheda prodotto',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Redazione premium',
  'Rédaction avec révision': 'Redazione smart + revision',
  'Rédaction intelligente': 'Redazione smart',
  PREMIUM: 'PREMIUM',
  Type: 'Tipo',
  Editer: 'Modifica',
  'Tous les types': 'Tutti i tipi',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Moteur de recherche Netlinking': 'Μηχανή αναζήτησης Netlinking',
  'Paramétres de recherche': 'Παράμετρος αναζήτησης',
  Standard: 'Κανονικό',
  Premium: 'Πρεμιέ',
  Thématique: 'Θεματική',
  'Filtre avancé': 'Προηγμένο φίλτρο',
  'Nombre de': 'Αριθμός',
  'Netlinking Trafic': 'Netlinking Κίνηση',
  'Mots clés positionnés': 'Κλειδιά θέσης',
  'Netlinking Tarif': 'Netlinking Τιμή',
  Chercher: 'Αναζήτηση',
  Domaine: 'Τομέας',
  'Topic Majestic': 'Majestic Θέμα',
  'Kw positionés': 'Κλειδιά θέσης',
  Trafic: 'Κίνηση',
  Tarif: 'Τιμή',
  'Contacté le': 'Επικοινωνία στις',
  Contact: 'Επικοινωνία',
  'Rechercher par nom de domaine': 'Αναζήτηση με όνομα τομέα',
  'Fiche de Spot Netlinking': 'Φύλλο Spot Netlinking',
  'Domaine Netlinking': 'Τομέας Netlinking',
  'Netlinking Thématique': 'Θεματική Netlinking',
  'Netlinking Topic Majestic': 'Θέμα Netlinking Majestic',
  Réinitialiser: 'Reimposta',
  italian: 'italiano',
  english: 'inglese',
  german: 'tedesco',
  spanish: 'spagnolo',
  portuguese: 'portoghese',
  french: 'francese',
};
