import { CustomAutocomplete } from 'components/shared/DropDown/CustomAutocomplete';
import { useTranslation } from 'react-i18next';

export const AssignSuperAdminEdit = (props) => {
    const { t } = useTranslation();

    return (
        <div className="row row-mb">
            <div className="col-12 col-md-8">
                <div className="label-title">{t(props.entityName)}</div>
                <CustomAutocomplete
                    disabled={!props.superAdminsList}
                    list={props.superAdminsList}
                    value={props.superAdmin}
                    setValue={(superAdmin) => {
                        props.updateSuperAdminList('email', superAdmin?.email);
                        props.updateSuperAdminList('firstName', superAdmin?.firstName);
                        props.updateSuperAdminList('lastName', superAdmin?.lastName);
                    }}
                    getOptionSelected={(option) => option.id === props.superAdmin.id}
                    getOptionDisabled={(option) => false}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option; // Handle string values directly
                        }

                        return option?.email == undefined ? '' : option?.email;
                    }}
                />
            </div>
            <div className="col-12 col-md-2">
                <div style={{ display: 'inline-block', maxWidth: 250 }}>
                    <div onClick={() => props.deleteSuperAdmin(props.id)}>
                        <div className="bt-minus-circle" style={{ marginTop: 25 }} />
                    </div>
                </div>
                <div style={{ display: 'inline-block', maxWidth: 250 }}>
                    <div onClick={props.addSuperAdmin}>
                        <div className="bt-plus-circle" style={{ marginTop: 25 }} />
                    </div>
                </div>
            </div>
        </div>
    );
};
